import { Row, Col, Card, Carousel, Modal, Button, Form } from "react-bootstrap";
import SideBar from "./sideBar";
import "../assets/css/home.css";
import Widget from "./Widget";
import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import "../assets/css/home.css";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import image1 from ".././img/image1.jpg";
import image2 from ".././img/image2.jpg";
import image3 from ".././img/image3.jpg";
import image4 from ".././img/image4.jpg";
import { useDispatch, useSelector } from "react-redux";
import { setUserInfo } from "../redux/userSlice";
import { fetchCountryCodes } from "../redux/countrySlice";
import { updateLoginTimestamp } from "../redux/loginSlice";

const API_BASE_URL = "https://crmapi.kozeglobal.com/api";
const DASHBOARD = `${API_BASE_URL}/get_dashboard.php`;
const LIVE_ACCOUNT_LIST = `${API_BASE_URL}/open_live_account_list.php`;
const ACCOUNT_INFO = `${API_BASE_URL}/getmt5_accinfo_api.php`;
const ACCOUNT_OPEN = `${API_BASE_URL}/open_live_account_add.php`;
const LIST = `${API_BASE_URL}/list_group.php`;
const PASSWORD = `${API_BASE_URL}/change_mt5_password.php`;
const BALANCE = `${API_BASE_URL}/get_user_bal_data.php`;
const DEMO = `${API_BASE_URL}/open_live_account_list_demo_group.php`;
const DEMO_ACCOUNT_OPEN = `${API_BASE_URL}/open_live_account_add_demo.php`;
const GET_USER = `${API_BASE_URL}/get_users.php`;

const Home = () => {
  const [userData, setUserData] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [accountList, setAccountList] = useState(null);
  const [error, setError] = useState(null);
  const [balance, setBalance] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalData, setModalData] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [demo, setDemo] = useState([]);
  const [live, setLive] = useState([]);
  const [liveIndex, setLiveIndex] = useState(0);
  const [demoIndex, setDemoIndex] = useState(0);
  const [formData, setFormData] = useState({
    groupname: "",
    leverage: "",
    mainpassword: "",
    investorpassword: "",
    groupid: "",
  });
  const [passwordModalVisible, setPasswordModalVisible] = useState(false);
  const [passwordFormData, setPasswordFormData] = useState({
    passwordType: "",
    mainPassword: "",
    investorPassword: "",
    mt5id: "",
    accountNumber: "",
  });
  const [demoModalVisible, setDemoModalVisible] = useState(false);
  const [demoFormData, setDemoFormData] = useState({
    groupname: "",
    leverage: "",
    mainpassword: "",
    investorpassword: "",
    groupid: "",
  });
  const [openAccountModalVisible, setOpenAccountModalVisible] = useState(false);
  const dispatch = useDispatch();

  const token = localStorage.getItem("userToken");

  useEffect(() => {
    const currentTime = new Date().getTime();
    localStorage.setItem("loginTimestamp", currentTime.toString());
    dispatch(updateLoginTimestamp());
  }, [dispatch]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.post(GET_USER, { token });

        if (response.data.data.response) {
          const { response: userData } = response.data.data;
          const userCountryId = userData.user_country || "";

          dispatch(
            setUserInfo({
              name: userData.user_name || "",
              email: userData.user_reg_code || "",
              mobile: userData.user_mobile || "",
              dob: userData.req_date || "",
              country: userCountryId,
              user_img: userData.user_img || "",
              authType: userData.user_auth_type || "",
            })
          );
        }
        // console.log(response);
      } catch (error) {
        console.log(error);
      }
    };
    fetchUser();
  }, []);

  const fetchCountries = useCallback(async () => {
    try {
      await dispatch(fetchCountryCodes());
    } catch (error) {
      console.error("Error fetching country codes:", error);
      toast.error("Failed to load countries.");
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchCountries();
  }, [fetchCountries]);

  const formatAmount = (amount) => {
    return amount ? parseFloat(amount).toFixed(2) : "-";
  };

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const response = await axios.post(BALANCE, { token });
        setData(response.data.data.response);
        console.log(response);
        setBalance(response.data.data.response.balance);
      } catch (error) {
        console.log("Error fetching balance:", error);
      }
    };
    fetchBalance();
  }, [token]);

  useEffect(() => {
    const fetchAccount = async () => {
      try {
        const response = await axios.post(LIVE_ACCOUNT_LIST, { token });
        console.log(response);
        if (response.data.data.status === 200) {
          const accountsData = response.data.data.response;
          if (Array.isArray(accountsData)) {
            setAccounts(accountsData);
          } else {
            console.log("No accounts found.");
          }
        }
      } catch (error) {
        console.log("Error fetching accounts:", error);
      }
    };

    fetchAccount();
  }, [token]);
  console.log(accounts);

  useEffect(() => {
    const fetchAccountTypes = async () => {
      try {
        const response = await axios.post(LIST, { token });
        if (response.data.data.status === 200) {
          setAccountList(response.data.data.response);
        }
      } catch (error) {
        console.error("Error fetching account types:", error);
      }
    };
    fetchAccountTypes();
  }, [token]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.post(DASHBOARD, { token });
        if (response.data?.data?.response) {
          setUserData(response.data.data.response);
        } else {
          setError("Unexpected response structure");
        }
      } catch (error) {
        setError("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [token]);

  useEffect(() => {
    const handleDemoAccount = async () => {
      try {
        const response = await axios.post(DEMO, { token });
        setDemo(response.data.data.response);
        console.log(response.data.data.response, "hello");
      } catch (error) {
        console.log(error);
      }
    };

    handleDemoAccount();
  }, [token]);

  const handleLiveAccount = async (accountNumber) => {
    const data = {
      token,
      mt5id: accountNumber,
    };
    try {
      const response = await axios.post(ACCOUNT_INFO, data);
      if (response.data.data.status === 200) {
        setModalData(response.data);
        setModalVisible(true);
      } else {
        toast.error("Unauthorized Access");
      }
    } catch (error) {
      toast.error("Failed to fetch live account data");
    }
  };

  const handleCardClick = (accountNumber) => {
    setPasswordFormData((prevData) => ({
      ...prevData,
      mt5id: accountNumber,
      accountNumber: accountNumber,
    }));
    setPasswordModalVisible(true);
  };

  const handlePasswordChangeSubmit = async () => {
    setLoading(true);
    console.log("click");

    const requestData = {
      token,
      passwordtype: passwordFormData.passwordType,
      mainpassword: passwordFormData.mainPassword,
      investorpassword: passwordFormData.investorPassword,
      mt5id: passwordFormData.mt5id,
    };
    console.log(requestData);
    try {
      const response = await axios.post(PASSWORD, requestData);
      if (response.data.data.status === 200) {
        toast.success("Password changed successfully");
      } else if (response.data.data.status === 400) {
        toast.error(
          "MAIN Password Must be min 8 charaters with mix of UPPERCASE,Lowercase,Special character and number"
        );
      } else {
        toast.error("Failed to change password");
      }
    } catch (error) {
      toast.error(
        "An error occurred: " + (error.response?.data?.message || error.message)
      );
    } finally {
      setLoading(false);
    }
    setFormData("");
  };

  const handleOpenNewAccount = async () => {
    const requestData = {
      selectgroup: formData.selectgroup,
      accleverage: formData.leverage,
      mainpassword: formData.mainpassword,
      investorpassword: formData.investorpassword,
      token,
    };
    console.log(requestData);

    try {
      const response = await axios.post(ACCOUNT_OPEN, requestData);
      if (response.data.data.status === 200) {
        toast.success("Account added successfully");
        setOpenAccountModalVisible(false);
        // Refresh the live accounts list
        const liveResponse = await axios.post(LIVE_ACCOUNT_LIST, { token });
        setAccounts(liveResponse.data.data.response);
      } else {
        toast.error(
          "Password must be at least 8 characters with a mix of uppercase, lowercase, special characters, and numbers"
        );
      }
    } catch (error) {
      console.error("Error opening new account:", error);
      toast.error("Failed to open new account. Please try again.");
    }
  };

  const handleLiveSelect = (selectedIndex) => {
    setLiveIndex(selectedIndex);
  };

  const handleDemoSelect = (selectedIndex) => {
    setDemoIndex(selectedIndex);
  };

  const handleOpenDemoAccount = async () => {
    const requestData = {
      selectgroup: demoFormData.groupname,
      accleverage: demoFormData.leverage,
      mainpassword: demoFormData.mainpassword,
      investorpassword: demoFormData.investorpassword,
      token,
    };
    console.log("Demo account request data:", requestData);

    try {
      const response = await axios.post(DEMO_ACCOUNT_OPEN, requestData);
      console.log("Demo account open response:", response.data);

      if (response.data.data.status === 200) {
        toast.success("Demo account added successfully");
        setDemoModalVisible(false);

        const demoResponse = await axios.post(DEMO, { token });
        setDemo(demoResponse.data.data.response);
      } else {
        toast.error(
          response.data.data.result ||
            "Failed to open demo account. Please try again."
        );
        setDemoModalVisible(false);
      }
    } catch (error) {
      console.error("Error opening demo account:", error);
      if (error.response) {
        console.log("Error response data:", error.response.data);
        console.log("Error response status:", error.response.status);
      }
      toast.error("An error occurred while opening the demo account");
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <SideBar />
      <div className=" container-fluid page-body-wrapper">
        <div className=" main-panel">
          <div className="content-wrapper">
            <div className="mb-4 row" style={{ justifyContent: "center" }}>
              <div
                className="col-lg-10 col-md-12 mb-4"
                style={{ margin: "2rem" }}
              >
                <Card className="shadow-sm">
                  <Card.Body className="card-body m-4 d-flex md:d-block">
                    <div className="card-body-size">
                      {data && (
                        <>
                          <h2 className="text-dark">
                            {formatAmount(balance)}
                            <span className="fs-6"> USD</span>
                          </h2>
                          <Row className="mt-3">
                            <Col sm={6}>
                              <div className=" fs-6 fs-md-5">
                                Credit Balance
                              </div>
                              <div className="fs-6 fs-md-5">
                                {data.creditbal || 0}
                                <span className="fs-7 fs-md-6"> USD</span>
                              </div>
                            </Col>
                            <Col sm={6}>
                              <div className=" fs-6 fs-md-5">Generate Date</div>
                              <div className="fs-6 fs-md-5">
                                {data.currentdate}
                              </div>
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <Col sm={6}>
                              <div className=" fs-6 fs-md-5">Debit Balance</div>
                              <div className="fs-6 fs-md-5">
                                {data.debitbal || "N/A"}
                                <span className="fs-7 fs-md-6"> USD</span>
                              </div>
                            </Col>
                            <Col sm={6}>
                              <div className=" fs-6 fs-md-5">
                                Total Withdrawal
                              </div>
                              <div className="fs-6 fs-md-5">
                                {data.totalwithdraw || "N/A"}
                                <span className="fs-7 fs-md-6"> USD</span>
                              </div>
                            </Col>
                            <div className="mt-4">
                              <button className="mx-1 me-1 mb-1 fs-6 fs-md-5 rounded">
                                <Link
                                  to={"/DepositeFunds"}
                                  style={{
                                    textDecoration: "none",
                                    color: "black",
                                  }}
                                >
                                  Deposit
                                </Link>
                              </button>
                              <button className="mx-1 me-1 mb-1 fs-6 fs-md-5 rounded">
                                <Link
                                  to={"/WithdrawFunds"}
                                  style={{
                                    textDecoration: "none",
                                    color: "black",
                                  }}
                                >
                                  Withdrawal
                                </Link>
                              </button>
                              <button className="mx-1 me-1 mb-1 fs-6 fs-md-5 rounded">
                                <Link
                                  to={"/TransferBetweenAccounts"}
                                  style={{
                                    textDecoration: "none",
                                    color: "black",
                                  }}
                                >
                                  Transfer
                                </Link>
                              </button>
                            </div>
                          </Row>
                        </>
                      )}
                    </div>

                    <div className="card-body-size mt-4">
                      {accounts.length > 0 ? (
                        <Carousel
                          activeIndex={liveIndex}
                          onSelect={handleLiveSelect}
                          indicators={true}
                          wrap={true}
                          className="custom-carousel"
                          interval={2000}
                          controls={false}
                          indicatorClassName="carousel-indicator-dark"
                          style={{ width: "100%" }}
                        >
                          {accounts.map((accountData, idx) => (
                            <Carousel.Item key={idx}>
                              <Card className="bg-light h-100 rounded">
                                <Card.Body className="d-flex flex-column p-2  rounded">
                                  <div className="mb-1 ">
                                    <span className=" fs-6 fs-md-5 fs-sm-3">
                                      MT5
                                    </span>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-center mb-2">
                                    <div>
                                      <img
                                        src={accountData.logourl}
                                        alt="USA Flag"
                                        className="me-1"
                                        width="20"
                                        height="20"
                                      />
                                      <span className="fs-6 fs-md-5 mb-0">
                                        {formatAmount(accountData.balance)} USD
                                      </span>
                                    </div>
                                  </div>
                                  <div className="mb-2">
                                    <button
                                      size="sm"
                                      className="me-1 mb-1 fs-6 fs-md-5 rounded"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleLiveAccount(accountData.accno);
                                      }}
                                    >
                                      Account Info
                                    </button>
                                    <button
                                      size="sm"
                                      className="fs-6 fs-md-5 rounded"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleCardClick(accountData.accno);
                                      }}
                                    >
                                      Change Password
                                    </button>
                                  </div>
                                  <div className="mt-auto small">
                                    <small className="text-muted fs-7 fs-md-6">
                                      {accountData.accno} |{" "}
                                      {accountData.groupname}
                                    </small>
                                  </div>
                                  <div className="small">
                                    <small className="text-muted fs-7 fs-md-6">
                                      Leverage: {accountData.leverages}
                                    </small>
                                  </div>
                                </Card.Body>
                              </Card>
                            </Carousel.Item>
                          ))}
                        </Carousel>
                      ) : (
                        <div className="text-center">
                          <p className="fs-6 fs-md-5 mb-3">
                            No Live accounts available.
                          </p>
                          <Button
                            variant="primary"
                            size="lg"
                            className="fs-6 fs-md-5"
                            onClick={() => setOpenAccountModalVisible(true)}
                          >
                            Open Account
                          </Button>
                        </div>
                      )}
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
            <div className="mb-4 d-flex " style={{ justifyContent: "center" }}>
              <Col className="col-lg-10 col-md-12 mb-4">
                <Card className="shadow-sm " style={{ borderColor: "#007bff" }}>
                  <Card.Body className="m-4">
                    <Carousel
                      interval={3000}
                      wrap={true}
                      indicators={false}
                      controls={true}
                    >
                      <Carousel.Item>
                        <div className="d-flex justify-content-between">
                          <img
                            className="d-none d-lg-block w-50 rounded me-2" // Add margin-end for spacing
                            src="https://png.pngtree.com/thumb_back/fh260/background/20230616/pngtree-upward-trending-stock-market-graph-in-3d-rendering-image_3617185.jpg"
                            alt="First slide"
                            style={{ height: "8rem", width: "8rem" }}
                          />
                          <img
                            className="d-none d-lg-block w-50 rounded" // Show on large screens
                            src="https://png.pngtree.com/thumb_back/fh260/background/20230616/pngtree-upward-trending-stock-market-graph-in-3d-rendering-image_3617185.jpg"
                            alt="Second slide"
                            style={{ height: "8rem", width: "8rem" }}
                          />
                          <img
                            className="d-block d-md-none w-100 rounded" // Show on medium screens
                            src="https://png.pngtree.com/thumb_back/fh260/background/20230616/pngtree-upward-trending-stock-market-graph-in-3d-rendering-image_3617185.jpg"
                            alt="Third slide"
                            style={{ height: "8rem", width: "8rem" }}
                          />
                        </div>
                      </Carousel.Item>
                      <Carousel.Item>
                        <div className="d-flex justify-content-between">
                          <img
                            className="d-none d-lg-block w-50 rounded me-2" // Add margin-end for spacing
                            src="https://png.pngtree.com/thumb_back/fh260/background/20230616/pngtree-upward-trending-stock-market-graph-in-3d-rendering-image_3617185.jpg"
                            alt="Fourth slide"
                            style={{ height: "8rem", width: "8rem" }}
                          />
                          <img
                            className="d-none d-lg-block w-50 rounded" // Show on large screens
                            src="https://png.pngtree.com/thumb_back/fh260/background/20230616/pngtree-upward-trending-stock-market-graph-in-3d-rendering-image_3617185.jpg"
                            alt="Fifth slide"
                            style={{ height: "8rem", width: "8rem" }}
                          />
                          <img
                            className="d-block d-md-none w-100 rounded" // Show on medium screens
                            src="https://png.pngtree.com/thumb_back/fh260/background/20230616/pngtree-upward-trending-stock-market-graph-in-3d-rendering-image_3617185.jpg"
                            alt="Sixth slide"
                            style={{ height: "8rem", width: "8rem" }}
                          />
                        </div>
                      </Carousel.Item>
                    </Carousel>
                  </Card.Body>
                </Card>
              </Col>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div className="widget">
                <Widget />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for opening a new account */}
      <Modal
        show={openAccountModalVisible}
        onHide={() => setOpenAccountModalVisible(false)}
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="fs-5 fs-md-4">Open Live Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              handleOpenNewAccount();
            }}
          >
            <Form.Group className="mb-3">
              <Form.Label className="fs-6 fs-md-5">Account Type</Form.Label>
              <Form.Control
                as="select"
                name="selectgroup"
                value={formData.selectgroup}
                onChange={(e) =>
                  setFormData({ ...formData, selectgroup: e.target.value })
                }
                required
                className="fs-6 fs-md-5"
              >
                <option value="">Select Account Type</option>
                {accountList && accountList.length > 0 ? (
                  accountList.map((account, index) => (
                    <option key={index} value={account.groupid}>
                      {account.groupname}
                    </option>
                  ))
                ) : (
                  <option value="">No account types available</option>
                )}
              </Form.Control>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="fs-6 fs-md-5">Select Leverage</Form.Label>
              <Form.Control
                as="select"
                name="leverage"
                value={formData.leverage}
                onChange={(e) =>
                  setFormData({ ...formData, leverage: e.target.value })
                }
                required
                className="fs-6 fs-md-5"
              >
                <option value="">Select Leverage</option>
                {[10, 50, 100, 200, 300, 500, 800, 1000].map((leverage) => (
                  <option key={leverage} value={leverage.toString()}>
                    {leverage}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="fs-6 fs-md-5">Main Password</Form.Label>
              <Form.Control
                type="password"
                name="mainpassword"
                value={formData.mainpassword}
                onChange={(e) =>
                  setFormData({ ...formData, mainpassword: e.target.value })
                }
                placeholder="Enter Main Password"
                required
                className="fs-6 fs-md-5"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label className="fs-6 fs-md-5">
                Investor Password
              </Form.Label>
              <Form.Control
                type="password"
                name="investorpassword"
                value={formData.investorpassword}
                onChange={(e) =>
                  setFormData({ ...formData, investorpassword: e.target.value })
                }
                placeholder="Enter Investor Password"
                required
                className="fs-6 fs-md-5"
              />
            </Form.Group>

            <button type="submit" className="mt-3 fs-6 fs-md-5">
              Submit
            </button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Password Change Modal */}
      <Modal
        show={passwordModalVisible}
        onHide={() => setPasswordModalVisible(false)}
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="fs-5 fs-md-4">Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              handlePasswordChangeSubmit();
            }}
          >
            <Form.Group className="mb-3">
              <Form.Label className="fs-6 fs-md-5">Account Number</Form.Label>
              <Form.Control
                type="text"
                value={passwordFormData.accountNumber}
                disabled
                className="fs-6 fs-md-5"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formPasswordType">
              <Form.Label className="fs-6 fs-md-5">Password Type</Form.Label>
              <Form.Control
                as="select"
                name="passwordType"
                value={passwordFormData.passwordType}
                onChange={(e) =>
                  setPasswordFormData({
                    ...passwordFormData,
                    passwordType: e.target.value,
                  })
                }
                required
                className="fs-6 fs-md-5"
              >
                <option value="">Select Password Type</option>
                <option value="both">Both</option>
                <option value="main">Main</option>
                <option value="investor">Investor</option>
              </Form.Control>
            </Form.Group>
            {(passwordFormData.passwordType === "both" ||
              passwordFormData.passwordType === "main") && (
              <Form.Group className="mb-3" controlId="formMainPassword">
                <Form.Label className="fs-6 fs-md-5">Main Password</Form.Label>
                <Form.Control
                  type="password"
                  name="mainPassword"
                  value={passwordFormData.mainPassword}
                  onChange={(e) =>
                    setPasswordFormData({
                      ...passwordFormData,
                      mainPassword: e.target.value,
                    })
                  }
                  required
                  className="fs-6 fs-md-5"
                />
              </Form.Group>
            )}
            {(passwordFormData.passwordType === "both" ||
              passwordFormData.passwordType === "investor") && (
              <Form.Group className="mb-3" controlId="formInvestorPassword">
                <Form.Label className="fs-6 fs-md-5">
                  Investor Password
                </Form.Label>
                <Form.Control
                  type="password"
                  name="investorPassword"
                  value={passwordFormData.investorPassword}
                  onChange={(e) =>
                    setPasswordFormData({
                      ...passwordFormData,
                      investorPassword: e.target.value,
                    })
                  }
                  required
                  className="fs-6 fs-md-5"
                />
              </Form.Group>
            )}
            <button type="submit" className="mt-3 fs-6 fs-md-5">
              Change Password
            </button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Modal for demo account */}
      <Modal
        show={demoModalVisible}
        onHide={() => setDemoModalVisible(false)}
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="fs-5 fs-md-4">Open Demo Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              handleOpenDemoAccount();
            }}
          >
            <Form.Group>
              <Form.Label className="fs-6 fs-md-5">Account Type</Form.Label>
              <Form.Control
                as="select"
                name="groupname"
                value={demoFormData.groupname}
                onChange={(e) =>
                  setDemoFormData({
                    ...demoFormData,
                    groupname: e.target.value,
                  })
                }
                required
                className="fs-6 fs-md-5"
              >
                <option value="">Select Account Type</option>
                {accountList && accountList.length > 0 ? (
                  accountList.map((account, index) => (
                    <option key={index} value={account.groupid}>
                      {account.groupname}
                    </option>
                  ))
                ) : (
                  <option value="">No account types available</option>
                )}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label className="fs-6 fs-md-5">Select Leverage</Form.Label>
              <Form.Control
                as="select"
                name="leverage"
                value={demoFormData.leverage}
                onChange={(e) =>
                  setDemoFormData({ ...demoFormData, leverage: e.target.value })
                }
                required
                className="fs-6 fs-md-5"
              >
                <option value="">Select Leverage</option>
                {[10, 50, 100, 200, 300, 500, 800, 1000].map((leverage) => (
                  <option key={leverage} value={leverage.toString()}>
                    {leverage}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label className="fs-6 fs-md-5">Main Password</Form.Label>
              <Form.Control
                type="password"
                name="mainpassword"
                value={demoFormData.mainpassword}
                onChange={(e) =>
                  setDemoFormData({
                    ...demoFormData,
                    mainpassword: e.target.value,
                  })
                }
                placeholder="Enter Main Password"
                required
                className="fs-6 fs-md-5"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="fs-6 fs-md-5">
                Investor Password
              </Form.Label>
              <Form.Control
                type="password"
                name="investorpassword"
                value={demoFormData.investorpassword}
                onChange={(e) =>
                  setDemoFormData({
                    ...demoFormData,
                    investorpassword: e.target.value,
                  })
                }
                placeholder="Enter Investor Password"
                required
                className="fs-6 fs-md-5"
              />
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              className="mt-3 fs-6 fs-md-5"
            >
              Open Demo Account
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Home;
