import React from "react";
import SideBar from "./sideBar";
import logo from "../img/favicon.png";
import logoRedeem from "../img/logo-redeem.png";
import { Link } from "react-router-dom";
import Reward from "../img/luckyDraw.jpg";

export default function LuckyDraw() {
  return (
    <>
      <SideBar />
      <div className="container-fluid page-body-wrapper" id="LuckyDraw">
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="reward-points">
              <div className="row g-3">
                <div className="col-12 col-lg-8">
                  <div
                    className="card h-100 d-flex justify-content-center px-3 px-md-5 py-3"
                    style={{
                      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${Reward})`,
                      objectFit: "contain",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                    }}
                  >
                    <h3 style={{ color: "white" }}>KOZE GLOBAL Rewards</h3>
                    <p style={{ color: "white" }}>
                      Reap more rewards as you trade
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="card h-100">
                    <div className="card-body p-3">
                      <p className="d-flex align-items-center gap-3">
                        <img
                          src={logo}
                          alt=""
                          className="img-fluid"
                          style={{ maxWidth: "30px" }}
                        />
                        <span>K-Points</span>
                        <i className="bi bi-info-circle-fill"></i>
                      </p>
                      <h1>0</h1>
                      <p className="m-0">
                        Make your first deposit to unlock redemption
                      </p>
                    </div>
                    <div className="card-footer p-3 d-flex justify-content-between align-items-center">
                      <Link to="#" className="text-decoration-none text-black">
                        Terms and Conditions
                      </Link>
                      <i className="bi bi-chevron-right"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card p-3 p-md-5 mt-3">
              <div className="row g-4">
                <div className="col-12 col-lg-6">
                  <h1 className="fw-bolder">Lucky Draw</h1>
                  <p className="fs-6">
                    Earn entries and stand a chance to win a USD$20 cash prize.
                  </p>
                  <p className="fs-6">
                    Each entry requires 1 raffle ticket, which can be obtained
                    with every 50 K-Points. There is no limit to the number of
                    raffle tickets you can redeem per week. The more tickets you
                    redeem, the higher your chances of winning!
                  </p>
                  <p className="fs-6 fw-bold">Result Annoucement</p>
                  <p className="fs-6">
                    The winning Ticket Numbers will be announced on this page
                    every Monday at 7:00 PM UTC (+2).
                  </p>
                  <p className="fs-6">
                    Winners of the weekly draw will also receive an email
                    notification with more details.
                  </p>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="card p-3 p-md-4">
                    <div className="d-flex align-items-center flex-column flex-sm-row">
                      <img
                        src={logoRedeem}
                        alt="Cash"
                        className="Cash-icon img-fluid mb-3 mb-sm-0 me-sm-3"
                      />
                      <div>
                        <h5 className="mb-3 mb-md-4">Ongoing Draw Period</h5>
                        <p className="fs-6">
                          <span className="fw-semibold">11/03/24</span> 00:00:00
                          - <span className="fw-semibold">17/03/24</span>{" "}
                          23:59:59 UTC (+2)
                        </p>
                      </div>
                    </div>
                    <p className="mt-3">
                      Last step, deposit now to Redeem Raffle Ticket
                    </p>
                    <button className="btn text-black border fs-5 rounded-3 w-100">
                      Redeem Tickets
                    </button>
                  </div>
                  <div className="text-center mt-4">
                    <Link className="text-decoration-none text-black">
                      View My Raffle Ticket <i class="bi bi-chevron-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
