import React, { useEffect, useState } from "react";
import { Button, Form, Card, Alert } from "react-bootstrap";
import SideBar from "./sideBar";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateLoginTimestamp } from "../redux/loginSlice";

const BASE_API = "https://crmapi.kozeglobal.com/api/";
const RESET = `${BASE_API}resetpassword.php`;

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const userInfo = useSelector((state) => state.user);

  const dispatch = useDispatch();

  useEffect(() => {
    const updateTimestamp = () => {
      const currentTime = new Date().getTime();
      localStorage.setItem("loginTimestamp", currentTime.toString());
      dispatch(updateLoginTimestamp());
    };

    updateTimestamp();
  }, [dispatch]);

  useEffect(() => {
    if (userInfo.userInfo && userInfo.userInfo.email) {
      setEmail(userInfo.userInfo.email);
    }
  }, [userInfo]);

  const isPasswordValid = password === confirmPassword && password.length >= 6;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      setError("Email is required");
      return;
    }

    const data = {
      email,
      password,
      confirmPassword,
    };
    console.log(data);

    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(RESET, data);
      console.log(response, "reset password");
      if (response.data.data.status === 200) {
        toast.success(response.data.data.result);
      }
    } catch (error) {
      setError("There was an error resetting the password.");
      console.error("Error resetting password:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <SideBar />
      <div>
        <div className="container-fluid page-body-wrapper">
          <div className="main-panel" style={{ backgroundColor: "#f5f5f5" }}>
            <div className="content-wrapper">
              <div className="page-header mb-4">
                <h3 className="page-title">Reset Password</h3>
              </div>

              <div className="row">
                <div className="col-lg-12 col-md-12 col-xs-12 col-12">
                  <Card>
                    <Card.Header>
                      <h4 className="card-title">Change Password</h4>
                    </Card.Header>
                    <Card.Body className="p-3 text-black">
                      <Form
                        name="change_password"
                        method="post"
                        noValidate
                        className="needs-validation"
                        onSubmit={handleSubmit}
                      >
                        <Form.Group controlId="formNewPassword">
                          <Form.Label>New Password</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="Enter new password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                          />
                        </Form.Group>

                        <Form.Group controlId="formConfirmPassword">
                          <Form.Label>Confirm Password</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="Confirm new password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                          />
                        </Form.Group>

                        {error && (
                          <Alert variant="danger" className="mt-3">
                            {error}
                          </Alert>
                        )}

                        <button
                          type="submit"
                          disabled={!isPasswordValid || loading}
                          className="mt-4 text-black border rounded"
                        >
                          {loading ? "Submitting..." : "Submit"}
                        </button>
                      </Form>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
