import React, { useEffect, useRef, useState } from "react";
import SideBar from "./sideBar";
import "../assets/css/ProTraderTools.css";
import Indices from "./TechnicalAnalys/Indices";
import Forex from "./TechnicalAnalys/Forex";
import Commodities from "./TechnicalAnalys/Commodities";
import ShareCFDs from "./TechnicalAnalys/ShareCFDs";
import CryptoAnalysis from "./TechnicalAnalys/Crypto";

export default function TechnicalAnalys() {
  const [defaultAccount, setDefaultAccount] = useState(0);
  const TabButton = [
    {
      text: "Indices",
    },
    {
      text: "Forex",
    },
    {
      text: "Commodities",
    },
    {
      text: "Share CFDs",
    },
    {
      text: "Crypto",
    },
  ];

  const scrollContainerRef = useRef(null);
  const tabsRef = useRef(null);
  const tabRefs = useRef([]);

  const handleAccountType = (index) => {
    setDefaultAccount(index);
    if (scrollContainerRef.current && tabRefs.current[index]) {
      const container = scrollContainerRef.current;
      const button = tabRefs.current[index];
      const containerWidth = container.offsetWidth;
      const buttonWidth = button.offsetWidth;
      const buttonOffsetLeft = button.offsetLeft;

      // Adjusting the scroll calculation
      const scrollLeft =
        buttonOffsetLeft - containerWidth / 2 + buttonWidth / 2;

      container.scrollTo({
        left: scrollLeft,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <SideBar />

      <div
        className="container-fluid page-body-wrapper"
        id="TransactionHistory"
      >
        <div className="main-panel ">
          <div className="content-wrapper">
            <div className="pt-2" style={{ margin: "0 auto" }}>
              <div
                className="d-flex tab-analysis"
                ref={tabsRef}
                style={{ overflowX: "auto", whiteSpace: "nowrap" }}
              >
                <div className=" d-flex tab-analysis">
                  {TabButton.map((element, key) => (
                    <div
                      className="flex-shrink-0"
                      key={key}
                      ref={(el) => (tabRefs.current[key] = el)}
                    >
                      <button
                        className={`${
                          key === defaultAccount ? "is-active-tab" : "btn"
                        } btn text-black fs-5 fs-md-4 fs-lg-2 bg-white`}
                        onClick={() => handleAccountType(key)}
                      >
                        {element.text}
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-12 mt-2 d-flex tab-container">
              <div
                className="mt-4 rounded justify-content-center"
                style={{ width: "100%" }}
              >
                {defaultAccount === 0 ? (
                  <Indices />
                ) : defaultAccount === 1 ? (
                  <Forex />
                ) : defaultAccount === 2 ? (
                  <Commodities />
                ) : defaultAccount === 3 ? (
                  <ShareCFDs />
                ) : (
                  <CryptoAnalysis />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
