import React from 'react'

export default function Eligibility() {
  return (
    <>
      <div className='mx-auto text-center mt-5 px-3' id='Eligibility'>
        <div>
          <h4 class="text-warning mt-3 text-center">Experience Plus</h4>
          <h3 class="text-center">Unlock your premium services</h3>
        </div>
        <div className=''>
          
        </div>
      </div>
    </>
  )
}
