import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../assets/css/depositeFund.css";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { updateLoginTimestamp } from "../../redux/loginSlice";

const BASE_URL = "https://crmapi.kozeglobal.com/api/";
const BANK_TRANSFER = `${BASE_URL}withdraw_funds_add_wallet_bal.php`;

export default function WithdrawFromBank({ balance }) {
  const [formValues, setFormValues] = useState({
    amount: "",
    bankname: "",
    accholder: "",
    accno: "",
    ifsc: "",
    remark: "",
    transaction_id: "",
  });
  const token = localStorage.getItem("userToken");

  const dispatch = useDispatch();

  useEffect(() => {
    const updateTimestamp = () => {
      const currentTime = new Date().getTime();
      localStorage.setItem("loginTimestamp", currentTime.toString());
      dispatch(updateLoginTimestamp());
    };

    updateTimestamp();
  }, [dispatch]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const resetForm = () => {
    setFormValues({
      amount: "",
      bankname: "",
      accholder: "",
      accno: "",
      ifsc: "",
      remark: "",
      transaction_id: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      ...formValues,
      type: "addwithdrawbank",
      token: token,
    };

    try {
      const response = await axios.post(BANK_TRANSFER, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log(response);

      if (response.status === 200) {
        toast.success("Form submitted successfully!");
        resetForm();
      } else {
        toast.error("Form submission failed.");
      }
    } catch (error) {
      toast.error("An error occurred: " + error.message);
    }
  };

  return (
    <>
      <div className="card-body mt-4">
        <h4>Withdraw Wallet Balance (Bank)</h4>
        <b>Current Wallet Fund : {balance} $</b>
        <div className="row">
          <div className="col-md-6">
            <form
              id="withdrawbankform"
              name="withdrawbankform"
              onSubmit={handleSubmit}
            >
              <div className="form-group mt-3">
                <label> Enter Amount : </label>
                <input
                  type="text"
                  id="add_fund_amount_usdt"
                  name="amount"
                  className="form-control"
                  value={formValues.amount}
                  onChange={handleChange}
                  required
                  placeholder="Enter Amount"
                />
                <p id="usdvalue">USD:{formValues.amount}</p>
              </div>
              <div className="form-group row gap-3">
                <div className="col-12">
                  <label>Bank Name</label>
                  <input
                    type="text"
                    name="bankname"
                    className="form-control"
                    value={formValues.bankname}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12">
                  <label>Account Holder Name</label>
                  <input
                    type="text"
                    name="accholder"
                    className="form-control"
                    value={formValues.accholder}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12">
                  <label>Account Number</label>
                  <input
                    type="text"
                    name="accno"
                    className="form-control"
                    value={formValues.accno}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12">
                  <label>IFSC / Swift Code</label>
                  <input
                    type="text"
                    name="ifsc"
                    className="form-control"
                    value={formValues.ifsc}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12">
                  <label>Remark</label>
                  <input
                    type="text"
                    name="remark"
                    className="form-control"
                    value={formValues.remark}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12">
                  <label>Transaction ID</label>
                  <input
                    type="text"
                    name="transaction_id"
                    className="form-control"
                    value={formValues.transaction_id}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form-group mt-3">
                <div>
                  <div id="btnsubmit">
                    <button
                      type="submit"
                      id="submit"
                      className="btn text-black border rounded waves-effect waves-light"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
