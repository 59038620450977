import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { fetchCountryCodes, setSelectedCountry } from "../redux/countrySlice";
import { setUserInfo, setUserToken, setUserId } from "../redux/userSlice";
import "../assets/css/login-register.css";
import LoginImg from "../img/Login_illustrator.svg";
import Logo from "../img/logo.png";

export default function Register() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const countries = useSelector((state) => state.country.countries);
  const [inputFields, setInputFields] = useState({
    name: "",
    email: "",
    password: "",
    confirmpassword: "",
    dob: "",
    country: "",
    countryCode: "",
    countryId: "",
    mobileno: "",
    inputChecked: false,
  });
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    dispatch(fetchCountryCodes());
  }, [dispatch]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setInputFields((prevFields) => ({
      ...prevFields,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleCountryChange = (e) => {
    const selectedCountryCode = e.target.value;
    const selectedCountry = countries.find(
      (country) => country.countryCode === selectedCountryCode
    );

    if (selectedCountry) {
      setInputFields((prevFields) => ({
        ...prevFields,
        country: selectedCountry.countryName,
        countryCode: selectedCountry.countryCode,
        countryId: selectedCountry.countryId,
      }));

      dispatch(setSelectedCountry(selectedCountry));
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsDisabled(true);

    if (inputFields.password !== inputFields.confirmpassword) {
      toast.error("Password and Confirm Password did not match !!");
      setIsDisabled(false);
      return;
    }

    if (!inputFields.inputChecked) {
      toast.error("Please Approve Terms and Conditions !!");
      setIsDisabled(false);
      return;
    }

    console.log("Submitting payload:", inputFields);

    try {
      const response = await axios.post(
        "https://crmapi.kozeglobal.com/register.php",
        inputFields
      );
      console.log(response);

      const { data } = response;
      const status = data.status || (data.data && data.data.status);
      const result = data.data?.result || "Registration Successful!";

      if (status === 200) {
        const { userid, token } = data.data;

        dispatch(setUserId(userid));
        dispatch(setUserToken(token));

        dispatch(
          setUserInfo({
            name: inputFields.name,
            email: inputFields.email,
            mobile: inputFields.mobileno,
            country: inputFields.country,
            countryId: inputFields.countryId,
            auth_type: data.data.auth_type,
          })
        );

        toast.success(result);
        setTimeout(() => {
          navigate("/login", { replace: true });
        }, 1000);

        setInputFields({
          name: "",
          email: "",
          password: "",
          confirmpassword: "",
          dob: "",
          country: "",
          countryCode: "",
          countryId: "",
          mobileno: "",
          inputChecked: false,
        });
      } else if (status === 409) {
        toast.error(
          "This email is already registered. Please use a different email."
        );
      } else {
        toast.error(result || "Unexpected error occurred");
      }
    } catch (e) {
      console.error("Error during registration:", e);
      toast.error("Server Error");
    } finally {
      setIsDisabled(false);
    }
  };

  const RegisterArray = [
    {
      itemLabel: "Name",
      itemValue: inputFields.name,
      itemType: "text",
      itemPlaceholder: "Enter Full Name",
      itemName: "name",
    },
    {
      itemLabel: "Email Address",
      itemValue: inputFields.email,
      itemType: "email",
      itemPlaceholder: "Enter Email Address",
      itemName: "email",
    },
    {
      itemLabel: "Password",
      itemValue: inputFields.password,
      itemType: "password",
      itemPlaceholder: "Password",
      itemName: "password",
    },
    {
      itemLabel: "Confirm Password",
      itemValue: inputFields.confirmpassword,
      itemType: "password",
      itemPlaceholder: "Confirm Password",
      itemName: "confirmpassword",
    },
    {
      itemLabel: "Date of Birth",
      itemValue: inputFields.dob,
      itemType: "date",
      itemName: "dob",
    },
    {
      itemLabel: "Country",
      itemValue: inputFields.countryCode,
      itemType: "select",
      itemName: "country",
    },
    {
      itemLabel: "Contact Number",
      itemValue: inputFields.mobileno,
      itemType: "text",
      itemPlaceholder: "Enter Contact no.",
      itemName: "mobileno",
      maxlimit: 10,
    },
    {
      itemType: "checkbox",
      itemName: "inputChecked",
      itemChecked: inputFields.inputChecked,
    },
  ];

  return (
    <section id="login">
      <div style={{ height: "100vh" }} className="login-left-side">
        <div className="stretch-card">
          <div className="main-card flex-column flex-sm-row">
            <div className="card-illustrator">
              <img
                src={LoginImg}
                className="Illustrator"
                alt="icon-illustration"
              />
            </div>
            <div className="card-body">
              <div className="head">
                <img src={Logo} className="brand-logo" alt="icon-logo" />
                <h4 className="description">Register</h4>
              </div>
              <form className="forms-sample" onSubmit={handleFormSubmit}>
                {RegisterArray.slice(0, 2).map((element, key) => (
                  <div className="form-group" key={key}>
                    <label>{element.itemLabel}</label>
                    <input
                      type={element.itemType}
                      className="form-control"
                      placeholder={element.itemPlaceholder}
                      value={element.itemValue}
                      name={element.itemName}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                ))}

                <div className="d-md-flex align-items-center gap-1">
                  {RegisterArray.slice(2, 4).map((element, key) => (
                    <div className="form-group w-100" key={2 * key + 1}>
                      <label>{element.itemLabel}</label>
                      <input
                        type={element.itemType}
                        className="form-control"
                        placeholder={element.itemPlaceholder}
                        value={element.itemValue}
                        name={element.itemName}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                  ))}
                </div>

                <div className="form-group w-100">
                  <label>{RegisterArray[4].itemLabel}</label>
                  <input
                    type={RegisterArray[4].itemType}
                    className="form-control"
                    value={inputFields.dob}
                    name="dob"
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="d-md-flex align-items-center gap-1">
                  {RegisterArray.slice(5, RegisterArray.length - 1).map(
                    (element, key) => (
                      <div
                        className="form-group w-100"
                        key={`${element.itemName}-${key}`}
                      >
                        <label>{element.itemLabel}</label>
                        {element.itemType === "select" ? (
                          <select
                            className="form-control"
                            value={inputFields.countryCode}
                            name="countryCode"
                            onChange={handleCountryChange}
                            required
                          >
                            <option value="">-Select Country-</option>
                            {countries.map((option, index) => (
                              <option
                                value={option.countryCode}
                                key={`${option.countryCode}-${index}`}
                              >
                                {`${option.countryName} +${option.countryCode}`}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <input
                            type={element.itemType}
                            className="form-control"
                            placeholder={element.itemPlaceholder}
                            name={element.itemName}
                            value={element.itemValue}
                            onChange={handleInputChange}
                            maxLength={element.maxlimit}
                            required
                          />
                        )}
                      </div>
                    )
                  )}
                </div>

                <div className="form-check form-check-flat form-check-primary">
                  <input
                    type={RegisterArray[RegisterArray.length - 1].itemType}
                    checked={inputFields.inputChecked}
                    name={RegisterArray[RegisterArray.length - 1].itemName}
                    className="form-check-input bg-transparent border-white"
                    onChange={handleInputChange}
                  />{" "}
                  Please Agree with our Terms & Conditions
                </div>

                <button
                  type="submit"
                  className="btn text-black border rounded me-2"
                  disabled={isDisabled}
                >
                  Register
                </button>
                <Link to="/login" className="text-muted fw-light d-block mt-2">
                  Already have an Account? Sign In
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
