import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { useDispatch } from "react-redux";
import { updateLoginTimestamp } from "../../redux/loginSlice";

const BASE_URL =
  "https://crmapi.kozeglobal.com/api/deposit_funds_add_wallet_bal_cash.php";

export default function DepositFormCash({ balance }) {
  const [inputFields, setInputFields] = useState({
    amount: "",
    remark: "",
  });
  const dispatch = useDispatch();

  useEffect(() => {
    const updateTimestamp = () => {
      const currentTime = new Date().getTime();
      localStorage.setItem("loginTimestamp", currentTime.toString());
      dispatch(updateLoginTimestamp());
    };

    updateTimestamp();
  }, [dispatch]);

  const token = localStorage.getItem("userToken");

  const FieldArray = [
    {
      itemLabel: "Enter Amount",
      itemId: "add_fund_amount",
      itemtype: "text",
      itemname: "amount",
      itemplaceholder: "Enter Amount",
      maxlimit: 5,
      itemValue: inputFields.amount,
    },
    {
      itemLabel: "Enter Remark",
      itemId: "note",
      itemtype: "text",
      itemname: "remark",
      itemplaceholder: "Enter Remark",
      itemValue: inputFields.remark,
    },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "amount") {
      setInputFields({
        ...inputFields,
        [name]: value.replace(/\D/g, ""),
      });
    } else {
      setInputFields({
        ...inputFields,
        [name]: value,
      });
    }
  };

  const handleInputFormSubmit = async (e) => {
    e.preventDefault();

    if (!token) {
      toast.error("User token is missing.");
      return;
    }

    const data = {
      amount: inputFields.amount,
      remark: inputFields.remark,
      token: token,
    };

    try {
      const response = await axios.post(BASE_URL, data);
      console.log(response);
      setInputFields({
        amount: "",
        remark: "",
      });
      toast.success("Form Submitted Successfully !!");
    } catch (error) {
      console.error(error);
      toast.error("Failed to submit the form.");
    }
  };

  return (
    <div className="card-body mt-4">
      <h4>Add Wallet Balance (Cash)</h4>
      <b>Current Wallet Fund : {balance} USDT</b>
      <div className="row">
        <div className="col-md-12">
          <form
            id="ewalletfundreqformcash"
            name="ewalletfundreqformcash"
            action="#"
            onSubmit={handleInputFormSubmit}
          >
            {FieldArray.map((element, index) => (
              <div className="form-group mt-3" key={index}>
                <label>{element.itemLabel} :</label>
                <input
                  type={element.itemtype}
                  id={element.itemId}
                  name={element.itemname}
                  value={element.itemValue}
                  className="form-control"
                  required
                  placeholder={element.itemplaceholder}
                  onChange={handleChange}
                  maxLength={element.maxlimit}
                />
              </div>
            ))}

            <input
              type="hidden"
              name="type"
              id="type"
              value="addrepfundrequest_cash"
            />

            <div className="form-group mt-3">
              <div>
                <div id="btnsubmit">
                  <button
                    type="submit"
                    id="submit"
                    className="btn text-black rounded border waves-effect waves-light"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
