import { useState, useEffect } from "react";
import axios from "axios";
import SideBar from "./sideBar";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { updateLoginTimestamp } from "../redux/loginSlice";

const BASE_API = "https://crmapi.kozeglobal.com/api/";
const ACCOUNT_LIST = `${BASE_API}mt5_accounts_list.php`;
const TRANSFER = `${BASE_API}mt5_to_wallet_api.php`;

export default function MT5Wallet() {
  // Renamed to match file name
  const [accounts, setAccounts] = useState([]);
  const [fromAccount, setFromAccount] = useState("");
  const [amount, setAmount] = useState("");
  const [note, setNote] = useState("");
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("userToken");

  const dispatch = useDispatch();

  useEffect(() => {
    const updateTimestamp = () => {
      const currentTime = new Date().getTime();
      localStorage.setItem("loginTimestamp", currentTime.toString());
      dispatch(updateLoginTimestamp());
    };

    updateTimestamp();
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.post(ACCOUNT_LIST, { token });
        console.log(response);
        if (response.data.data.response) {
          setAccounts(response.data.data.response);
        } else {
          throw new Error("Invalid response format");
        }
      } catch (err) {
        console.error("Failed to fetch accounts", err);
        toast.error(
          err.message || "Failed to load accounts. Please try again."
        );
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const parsedAmount = parseFloat(amount);

    if (!fromAccount || !parsedAmount || !note) {
      toast.error("Please fill all fields");
      return;
    }

    if (parsedAmount <= 0) {
      toast.error("Amount must be a positive number");
      return;
    }

    const formData = {
      mt5accountselect: fromAccount,
      amount: parsedAmount,
      note,
      token,
    };

    setLoading(true);
    try {
      const response = await axios.post(TRANSFER, formData);
      const result = response.data.data.result;
      if (result) {
        toast.success(result);

        // Clear the form fields
        setFromAccount("");
        setAmount("");
        setNote("");
      } else {
        toast.error("Transfer failed. Please try again.");
      }
    } catch (err) {
      console.error("Submission error", err);
      if (err.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        toast.error(
          `Error: ${
            err.response.data.message || "An error occurred during the transfer"
          }`
        );
      } else if (err.request) {
        // The request was made but no response was received
        toast.error("No response received from server. Please try again.");
      } else {
        // Something happened in setting up the request that triggered an Error
        toast.error("An error occurred while setting up the request.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SideBar />
      <div
        className="container-fluid page-body-wrapper"
        id="TransferBetweenAccount"
      >
        <div className="main-panel">
          <div className="content-wrapper">
            <div
              className="row justify-content-center"
              style={{ marginTop: "2rem" }}
            >
              <div
                className="col-12 col-md-10 col-lg-8"
                style={{ width: "920px" }}
              >
                {/* <div className="page-header mb-4">
                  <h3 className="page-title">MT5 To Wallet</h3>
                </div> */}
                <div className="text-black">
                  <h3 className="funds m-3">MT5 To Wallet</h3>
                </div>
                <div className="card">
                  <div className="card-body ">
                    {loading && <p>Loading...</p>}
                    <form
                      id="mt5_to_mt5_transfer"
                      name="mt5_to_mt5_transfer"
                      autoComplete="off"
                      onSubmit={handleSubmit}
                    >
                      <div className="form-group mt-3">
                        <label htmlFor="mt5accountselectfrom">
                          From Account:
                        </label>
                        <select
                          name="mt5accountselectfrom"
                          id="mt5accountselectfrom"
                          className="form-control"
                          value={fromAccount}
                          onChange={(e) => setFromAccount(e.target.value)}
                        >
                          <option value="">Select Account</option>
                          {accounts.map((account) => (
                            <option key={account.id} value={account.id}>
                              {account.id}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="form-group mt-3">
                        <label htmlFor="amount">Amount:</label>
                        <input
                          type="text" // Changed to text
                          id="amount"
                          name="amount"
                          className="form-control"
                          value={amount}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (/^\d*\.?\d*$/.test(value)) setAmount(value);
                          }}
                          required
                        />
                      </div>
                      <div className="form-group mt-3">
                        <label htmlFor="note">Note:</label>
                        <input
                          type="text"
                          id="note"
                          name="note"
                          className="form-control"
                          value={note}
                          onChange={(e) => setNote(e.target.value)}
                          required
                        />
                      </div>
                      <input
                        type="hidden"
                        name="type"
                        id="type"
                        value="mt5_to_wallet_balance"
                      />
                      <div className="form-group mt-2 text-center">
                        <button
                          type="submit"
                          id="submit"
                          className="btn text-black border rounded"
                          disabled={loading}
                        >
                          {loading ? "Processing..." : "Submit"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
