import React from "react";
import SideBar from "./sideBar";
import logo from "../img/favicon.png";
import logoWhite from "../img/logoWhite.png";
import { Link } from "react-router-dom";
import Voucher from "../img/voucher.jpg";

export default function VouchersRedemption() {
  return (
    <>
      <SideBar />
      <div className="container-fluid page-body-wrapper" id="Vouchers">
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="reward-points">
              <div className="row">
                <div className="col-12 col-md-8 mb-3 mb-md-0">
                  <div
                    className="card h-100 d-flex justify-content-center px-5 py-3 text-white"
                    style={{
                      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${Voucher})`,
                      objectFit: "contain",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                    }}
                  >
                    <h3>KOZE GLOBAL Rewards</h3>
                    <p>Reap more rewards as you trade</p>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="card">
                    <div className="card-body p-3">
                      <p className="d-flex align-items-center gap-3">
                        <img src={logo} alt="" className="" />
                        <span>K-Points</span>
                        <i class="bi bi-info-circle-fill"></i>
                      </p>
                      <h1>0</h1>
                      <p className="m-0">
                        Make your first deposit to unlock redemptions
                      </p>
                    </div>
                    <div className="card-footer p-3 d-flex justify-content-between align-items-center">
                      <Link to="#" className="text-decoration-none text-black">
                        Terms and Conditions
                      </Link>
                      <i class="bi bi-chevron-right"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card p-4 mt-3">
              <div className="text-center">
                <h1>Vouchers Redemption</h1>
                <h4 className="fw-normal">
                  Redeem any of these vouchers with K-Points. Once voucher is
                  redeemed, it will be stored in My Voucher, and will expire
                  after 30 days.
                </h4>
              </div>
              <div className="row">
                <div className="col-12 col-md-6 mb-3">
                  <div className="card Vouchers">
                    <div className="d-flex align-items-center gap-2">
                      <img
                        src={logoWhite}
                        alt="Icon"
                        className="img-fluid"
                        style={{ maxWidth: "20px" }}
                      />
                      <div>
                        <h3 className="mb-2">Profit Booster up to $30</h3>
                        <p className="fs-6">
                          On a winning streak? Double your profits by redeeming
                          vouchers!{" "}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="d-flex align-items-center">
                        <span>K-Points</span>
                        <h1>1,000</h1>
                      </div>
                      <button className="btn text-white rounded-3 px-4">
                        REDEEM
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 mb-3">
                  {/* ... placeholder for additional voucher cards ... */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
