import React from "react";
import SideBar from "./sideBar";
import "../assets/css/downloads.css";
import Appstore from "../img/Apps Store.png";
import PlayStore from "../img/play store.png";
import { Link } from "react-router-dom";
import WindowsOs from "../img/windows8-96.png";
import MacOs from "../img/apple-logo-100.png";
import IOs from "../img/ios-logo-100.png";
import AndroidOs from "../img/android-logo-100.png";
import WebTrading from "../img/trading-80.png";

export default function DownloadApp() {
  const Downloads = [
    {
      Img: WindowsOs,
      OsName: "Windows",
    },
    {
      Img: MacOs,
      OsName: "Mac OS",
    },
    {
      Img: IOs,
      OsName: "IPhone/IPad",
    },
    {
      Img: AndroidOs,
      OsName: "Android",
    },
    {
      Img: WebTrading,
      OsName: "Web Trader",
    },
  ];
  return (
    <>
      <SideBar />
      <div className="container-fluid page-body-wrapper">
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="row justify-content-center">
              <div className="col-md-10">
                <div
                  className="card p-4"
                  style={{ width: "85%", margin: "0 auto" }}
                >
                  <div className="row app-store-box mb-5">
                    <div className="col-12 col-md-6 store-left">
                      <h1 className="text-uppercase">Koze Global app</h1>
                      <p>
                        Download the Koze Global App on App store and Google
                        Play Store
                      </p>
                      <div className="store-button">
                        <button className="AppStore-btn">
                          <img src={Appstore} alt="error-icon" />
                        </button>
                        <button className="Android-btn">
                          <img src={PlayStore} alt="error-icon" />
                        </button>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 store-right"></div>
                  </div>
                </div>
              </div>
            </div>

            <p className="mt-3 mb-4 fs-5 text-black text-center">
              Download Meta trader 4 Fro PC web browser smartphones and tablets
            </p>
            <div
              className="Trader d-flex flex-wrap justify-content-center gap-4"
              style={{ width: "85%", margin: "0 auto" }}
            >
              {Downloads.map((element, key) => (
                <div className="card download-card" key={key}>
                  <div className="py-4">
                    <Link
                      to=""
                      className="text-white text-decoration-none text-center d-flex flex-column gap-3"
                    >
                      <div className="picture">
                        <img src={element.Img} alt="Icons" />
                      </div>
                      <p>{element.OsName}</p>
                    </Link>
                  </div>
                </div>
              ))}
            </div>

            <p className="mt-3 mb-4 fs-5 text-black text-center">
              Download Meta trader 5 Fro PC web browser smartphones and tablets
            </p>
            <div
              className="Trader d-flex flex-wrap justify-content-center gap-4"
              style={{ width: "85%", margin: "0 auto" }}
            >
              {Downloads.map((element, key) => (
                <div className="card download-card" key={key}>
                  <div className="py-4">
                    <Link
                      to=""
                      className="text-white text-decoration-none text-center d-flex flex-column gap-3"
                    >
                      <div className="picture">
                        <img src={element.Img} alt="Icons" />
                      </div>
                      <p>{element.OsName}</p>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
