import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CookiesProvider, useCookies } from "react-cookie";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { loginRequest, loginSuccess, loginFailure } from "../redux/loginSlice";
import "../assets/css/login-register.css";
import LoginImg from "../img/Login_illustrator.svg";
import Logo from "../img/logo.png";
import axios from "axios";

const API_BASE_URL = "https://crmapi.kozeglobal.com/api/";
const API_LOGIN = `${API_BASE_URL}login.php`;
const API_VERIFY = `${API_BASE_URL}login_verify_otp.php`;

export default function LogIn() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cookies, setCookies, removeCookie] = useCookies(["remember"]);
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [showError, setShowError] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [otpMessage, setOtpMessage] = useState("");
  const otpBoxReference = useRef([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [inputFields, setInputFields] = useState({ email: "", password: "" });
  const [rememberMe, setRememberMe] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Invalid OTP. Please try again.");
  const [logoutTimer, setLogoutTimer] = useState(null);

  useEffect(() => {
    if (cookies.remember) {
      setInputFields(cookies.remember);
      setRememberMe(true);
    }
  }, [cookies.remember]);

  useEffect(() => {
    if (otp.join("").length === 6) {
      setShowError(false);
    }
  }, [otp]);

  const handleChange = (value, index) => {
    let newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    if (value && index < 5) {
      otpBoxReference.current[index + 1]?.focus();
    }
  };

  const handleBackspaceAndEnter = (e, index) => {
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      otpBoxReference.current[index - 1]?.focus();
    }
    if (e.key === "Enter" && e.target.value && index < 5) {
      otpBoxReference.current[index + 1]?.focus();
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputFields((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!inputFields.email || !inputFields.password) {
      toast.error("Please fill in all fields.");
      return;
    }

    try {
      dispatch(loginRequest());
      setIsDisabled(true);

      const response = await axios.post(API_LOGIN, inputFields);

      console.log(response);
      const apiResponse = response.data.data;

      if (apiResponse.status === 200) {
        const { token = "default_token", authType } =
          apiResponse.response || {};

        // Save token and login time to localStorage
        const loginTime = Date.now();
        localStorage.setItem("userToken", JSON.stringify({ token, loginTime }));

        dispatch(loginSuccess({ token }));

        // Navigate to home page after successful login
        navigate("/", { replace: true });

        switch (authType) {
          case 0:
            toast.success("Login successful");
            break;
          case 1:
            toast.success(
              "OTP has been sent. Please check your Google Authenticator for the OTP."
            );
            setOtpMessage("Enter the Google OTP:");
            setShowOtpModal(true);
            break;
          case 2:
            toast.success(
              "OTP has been sent. Please check your email for the OTP."
            );
            setOtpMessage("Enter the email OTP:");
            setShowOtpModal(true);
            break;
          default:
            toast.success("Login successful");
        }
      } else {
        const errorMsg = apiResponse.result || "Something went wrong!";
        dispatch(loginFailure(errorMsg));
        toast.error(errorMsg);
      }
    } catch (error) {
      dispatch(loginFailure("Server Error !!"));
      toast.error(error.message || "Server Error !!");
    } finally {
      setIsDisabled(false);
    }
  };

  const verifyOtpAndLogin = async () => {
    const otpValue = otp.join("");
    if (otpValue.length === 6) {
      try {
        dispatch(loginRequest());
        setIsDisabled(true);

        const storedToken = localStorage.getItem("userToken");
        if (!storedToken) {
          throw new Error("No token found.");
        }

        const data = {
          otp: otpValue,
          token: storedToken,
        };

        const response = await axios.post(API_VERIFY, data);
        console.log(response);
        const apiResponse = response.data.data;

        if (apiResponse.status === 200) {
          toast.success("Logged In Successfully !!");

          dispatch(loginSuccess());
          navigate("/", { replace: true });

          if (rememberMe) {
            setCookies("remember", inputFields, { path: "/" });
          } else {
            removeCookie("remember", { path: "/" });
          }
        } else {
          const errorMsg = apiResponse.result || "Something went wrong!";
          dispatch(loginFailure(errorMsg));
          toast.error(errorMsg);
        }
      } catch (error) {
        dispatch(loginFailure(error.message || "Server Error !!"));
        toast.error(error.message || "Server Error !!");
      } finally {
        setIsDisabled(false);
      }
    } else {
      setShowError(true);
    }
  };

  useEffect(() => {
    const storedToken = localStorage.getItem("userToken");
    if (storedToken) {
      try {
        let parsedToken;
        // Try to parse the stored token
        try {
          parsedToken = JSON.parse(storedToken);
        } catch (parseError) {
          // If parsing fails, assume it's a plain string token
          parsedToken = { token: storedToken, loginTime: Date.now() };
        }

        const { token, loginTime } = parsedToken;
        const currentTime = Date.now();
        const twoHoursInMs = 2 * 60 * 1000;

        if (currentTime - loginTime < twoHoursInMs) {
          dispatch(loginSuccess({ token }));
          navigate("/", { replace: true });
        } else {
          localStorage.removeItem("userToken");
        }
      } catch (error) {
        console.error("Error handling stored token:", error);
        localStorage.removeItem("userToken");
      }
    }
  }, [dispatch, navigate]);

  useEffect(() => {
    const handleActivity = () => {
      clearTimeout(logoutTimer);
      setLogoutTimer(
        setTimeout(() => {
          dispatch(loginFailure("Session expired. Please log in again."));
          localStorage.removeItem("userToken");
          navigate("/login");
        }, 2 * 60 * 60 * 1000)
      );
    };

    let logoutTimer = setTimeout(() => {}, 2 * 60 * 60 * 1000);

    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keypress", handleActivity);

    return () => {
      clearTimeout(logoutTimer);
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keypress", handleActivity);
    };
  }, [dispatch, navigate]);

  return (
    <>
      <CookiesProvider>
        <section id="login">
          <div style={{ height: "100vh" }} className="login-left-side">
            <div className="stretch-card">
              <div className="main-card flex-column flex-sm-row">
                <div className="card-illustrator">
                  <img
                    src={LoginImg}
                    className="Illustrator"
                    alt="Login Illustration"
                  />
                </div>
                <div className="card-body">
                  <div className="head">
                    <img
                      src={Logo}
                      className="brand-logo"
                      alt="Logo"
                      style={{ height: "5rem" }}
                    />
                    <h4 className="description">Login</h4>
                  </div>
                  <form
                    className="forms-sample mt-5"
                    onSubmit={handleFormSubmit}
                  >
                    {[
                      {
                        labelText: "exampleInputEmail1",
                        labelContent: "Email",
                        inputType: "email",
                        inputId: "exampleInputEmail1",
                        inputPlaceholder: "Email",
                        inputName: "email",
                        value: inputFields.email,
                      },
                      {
                        labelText: "exampleInputPassword1",
                        labelContent: "Password",
                        inputType: "password",
                        inputId: "exampleInputPassword1",
                        inputPlaceholder: "Password",
                        inputName: "password",
                        value: inputFields.password,
                      },
                    ].map((element, key) => (
                      <div className="form-group" key={key}>
                        <label htmlFor={element.labelText}>
                          {element.labelContent}
                        </label>
                        <input
                          type={element.inputType}
                          className="form-control"
                          id={element.inputId}
                          placeholder={element.inputPlaceholder}
                          onChange={handleInputChange}
                          name={element.inputName}
                          required
                          value={element.value || ""}
                        />
                      </div>
                    ))}

                    <button
                      type="submit"
                      className="btn btn-primary mt-1 mb-3 d-block"
                      disabled={isDisabled}
                    >
                      Login
                    </button>
                    <div className="form-check form-check-flat form-check-primary d-flex flex-column gap-3">
                      <div className="d-flex align-items-center">
                        <input
                          type="checkbox"
                          className="form-check-input bg-transparent border-white"
                          style={{ transform: "scale(0.75)" }}
                          onChange={() => setRememberMe(!rememberMe)}
                          checked={rememberMe}
                        />
                        <label
                          className="form-check-label mt-1"
                          style={{ fontSize: "0.75rem" }}
                        >
                          Remember me
                        </label>
                      </div>

                      <Link
                        to="/forgot-password"
                        className="text-white text-decoration-none"
                        style={{ fontSize: "0.75rem" }}
                      >
                        Forgot Password?
                      </Link>

                      <Link
                        to="/register"
                        className="text-muted fw-light"
                        style={{ fontSize: "0.75rem" }}
                      >
                        New user? Create an account
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </CookiesProvider>

      <Modal
        show={showOtpModal}
        onHide={() => setShowOtpModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Button className="BackButton" onClick={() => setShowOtpModal(false)}>
            <i className="bi bi-chevron-left fs-6"></i> Back
          </Button>
        </Modal.Header>
        <Modal.Body>
          <h2 className="fs-5">{otpMessage}</h2>
          <div className="w-100 d-flex justify-content-between my-3">
            {otp.map((digit, index) => (
              <div className="round" key={index}>
                <input
                  value={digit}
                  maxLength={1}
                  onChange={(e) => handleChange(e.target.value, index)}
                  onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
                  ref={(reference) =>
                    (otpBoxReference.current[index] = reference)
                  }
                />
              </div>
            ))}
          </div>
          <p className="error-para">{showError && errorMsg}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary" onClick={verifyOtpAndLogin}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
