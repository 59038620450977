import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { TechnicalAnalysis } from "react-ts-tradingview-widgets";
import { updateLoginTimestamp } from "../../redux/loginSlice";

export default function Indices() {
  const dispatch = useDispatch();

  useEffect(() => {
    const updateTimestamp = () => {
      const currentTime = new Date().getTime();
      localStorage.setItem("loginTimestamp", currentTime.toString());
      dispatch(updateLoginTimestamp());
    };

    updateTimestamp();
  }, [dispatch]);

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-md-6 col-sm-6 col-xs-12">
          <div className="card p-3">
            <div className="row justify-content-center">
              <div className="widgets col-12">
                <TechnicalAnalysis
                  symbol="NDAQ"
                  colorTheme="dark"
                  width="100%"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
