import { useEffect, useState } from "react";
import SideBar from "./sideBar";
import { Button, Table, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { updateLoginTimestamp } from "../redux/loginSlice";

const BASE_API = "https://crmapi.kozeglobal.com/api/";
const TICKET_CREATE = `${BASE_API}support_ticket.php`;
const GET_TICKET = `${BASE_API}get_support_ticket.php`;

const Support = () => {
  const [category, setCategory] = useState("");
  const [question, setQuestion] = useState("");
  const [image, setImage] = useState(null);
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem("userToken");

  const dispatch = useDispatch();

  useEffect(() => {
    const updateTimestamp = () => {
      const currentTime = new Date().getTime();
      localStorage.setItem("loginTimestamp", currentTime.toString());
      dispatch(updateLoginTimestamp());
    };

    updateTimestamp();
  }, [dispatch]);

  const handleImageUpload = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!token) {
      toast.error("You are not authenticated. Please log in.");
      return;
    }

    let imageBase64 = "";
    if (image) {
      if (image.size > 5 * 1024 * 1024) {
        // 5MB limit
        toast.error("Image size should be less than 5MB.");
        return;
      }
      try {
        imageBase64 = await handleImageUpload(image);
      } catch (error) {
        console.error("Error converting image to Base64:", error);
        toast.error("Failed to convert image.");
        return;
      }
    }

    const data = {
      image: imageBase64,
      category,
      question,
      token,
    };

    try {
      const response = await axios.post(TICKET_CREATE, data);

      if (response.data.data.status === 200) {
        toast.success("Ticket submitted successfully!");
        setCategory("");
        setQuestion("");
        setImage(null);
        document.getElementById("image-upload-1").value = null;
        fetchTickets();
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.error("Error submitting ticket:", error);
      toast.error("Failed to submit the ticket.");
    }
  };

  const fetchTickets = async () => {
    setLoading(true);
    try {
      const response = await axios.post(GET_TICKET, { token });
      if (response.data.data.status === 200) {
        setTickets(response.data.data.response);
      } else {
        toast.error("Failed to load tickets: " + response.data.data.message);
        setTickets([]);
      }
    } catch (error) {
      console.error("Failed to fetch tickets", error);
      toast.error("Failed to load tickets. Please try again later.");
      setTickets([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTickets();
  }, []);

  const handleClicked = (s_id) => {
    navigate(`/support/${s_id}`);
  };

  return (
    <>
      <SideBar />
      <div className="container-fluid page-body-wrapper">
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="row">
              <div className="col-md-12 mb-4" style={{ marginTop: "2rem" }}>
                <div className="card item" style={{ margin: "0 auto" }}>
                  <div className="card-body m-4">
                    <h5 className="card-title mb-4 text-black text-center">
                      Create Support Ticket
                    </h5>

                    <form onSubmit={handleSubmit}>
                      <div className="form-group mb-4">
                        <label
                          htmlFor="category-select-1"
                          className="form-label"
                        >
                          Select Category
                        </label>
                        <select
                          className="form-control"
                          id="category-select-1"
                          value={category}
                          onChange={(e) => setCategory(e.target.value)}
                        >
                          <option value="">Select Category</option>
                          <option value="Registration Problem">
                            Registration Problem
                          </option>
                          <option value="Deposit Problem">
                            Deposit Problem
                          </option>
                          <option value="Withdrawal Not Received">
                            Withdrawal Not Received
                          </option>
                          <option value="Other">Other</option>
                        </select>
                      </div>

                      <div className="form-group mb-4">
                        <label
                          htmlFor="question-input-1"
                          className="form-label"
                        >
                          Enter Question
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="question-input-1"
                          placeholder="Enter your question"
                          value={question}
                          onChange={(e) => setQuestion(e.target.value)}
                          style={{ width: "100%" }}
                        />
                      </div>

                      <div className="form-group mb-4">
                        <label htmlFor="image-upload-1" className="form-label">
                          Upload Image (if any)
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          id="image-upload-1"
                          accept="image/*"
                          onChange={(e) => setImage(e.target.files[0])}
                        />
                      </div>

                      <div className="text-center">
                        <button
                          type="submit"
                          className="text-black border rounded"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="col-md-12 mb-4">
                <div className="card item" style={{ margin: "0 auto" }}>
                  <div className="card-body m-4">
                    <h5 className="card-title mb-4 text-black text-center">
                      List of Support Tickets
                    </h5>

                    {loading ? (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: "200px" }}
                      >
                        <Spinner animation="border" />
                      </div>
                    ) : (
                      <div className="table-responsive">
                        <Table striped className="custom-table">
                          <thead>
                            <tr>
                              <th></th>
                              <th>Date</th>
                              <th>Category</th>
                              <th>Question</th>
                              <th>Status</th>
                              <th>Support ID</th>
                              <th>Details</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Array.isArray(tickets) && tickets.length > 0 ? (
                              tickets.map((row, id) => (
                                <tr
                                  key={row.id}
                                  onClick={() => handleClicked(row.s_id)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <td>{id + 1}</td>
                                  <td>{row.date}</td>
                                  <td>{row.Category}</td>
                                  <td>{row.Question}</td>
                                  <td>{row.Status}</td>
                                  <td>{row.s_code}</td>
                                  <td>
                                    <button className="text-black border rounded">
                                      Details
                                    </button>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="7">No tickets available</td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Support;
