import React from "react";

export default function LernFromBest() {
  return (
    <>
      <div className="mx-auto text-center mt-5 px-3">
        <div className="row align-items-center">
          <div className="col-12 col-md-6 text-start">
            <h3 className="mt-3">Learn from the best</h3>
            <p className="fs-6">
              Practice your analysis and get valuable feedback from our team of
              top tier analysts as they help you work your way towards being a
              profitable trader.
            </p>
          </div>
          <div className="col-12 col-md-6 text-start">
            <h3 className="mt-3">Practice your analysis on our platform</h3>
            <p className="fs-6">
              Koze Global Markets VIP Room comes with a full-fledge charting
              platform where you can practice your analysis and even save your
              layouts.
            </p>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-12 col-md-6 text-start">
            <h3 className="mt-3">
              Seek a second opinion from our top tier analysts
            </h3>
            <p className="fs-6 small">
              Ever wondered if you were drawing that Fibonacci Retracement
              correctly? Or if you identified the correct support/resistance
              areas?
            </p>
            <p className="fs-6 small">
              Our platform allows you to easily ask a question {">"} link it to
              a drawing/indicator on your chart {">"} and @mention an analyst to
              get meaningful feedback that will help you improve your trading.
            </p>
          </div>
          <div className="col-12 col-md-6 text-start">
            <h3 className="mt-3">You're in good hands​</h3>
            <p className="fs-6 small">
              You're learning from the best in the industry. Our pool of
              analysts boast being finalists in the 2019, 2020 and 2021 Best FX
              & Equity Research by the prestigious TTA Awards.
            </p>
            <p className="fs-6">
              Along with that, you'll have a direct line to CFA, CMT, CFTe
              accredited award winning traders.
            </p>
          </div>
        </div>

        <div className="w-100 text-white">
          <div
            className="py-2 px-2  rounded-4"
            style={{ background: "#1c1c1c" }}
          >
            <div className="mb-5">
              <h2 className="fw-bolder">Join Koze Global Markets VIP Today!</h2>
            </div>
            <div>
              <p className="fs-6 small">
                Take your trading to the next level by getting daily exclusive
                market insights and guidance from our pool of award-winning
                traders. Simply deposit the relevant amount into your Koze
                Global Markets trading account and the different access levels
                will be unlocked for you.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
