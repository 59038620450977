import React, { useEffect } from "react";
import SideBar from "./sideBar";
import "../assets/css/ProTraderTools.css";
import marketBuzz from "../img/MarketBuzz.png";
import techInsights from "../img/TechnicalInsights.png";
import featuredIdeas from "../img/FeaturedIdeas.png";
import { useDispatch } from "react-redux";
import { updateLoginTimestamp } from "../redux/loginSlice";

export default function ProTraderTools() {
  const dispatch = useDispatch();

  useEffect(() => {
    const updateTimestamp = () => {
      const currentTime = new Date().getTime();
      localStorage.setItem("loginTimestamp", currentTime.toString());
      dispatch(updateLoginTimestamp());
    };

    updateTimestamp();
  }, [dispatch]);

  const ToolsCard = [
    {
      ToolCardTitle: "Market Buzz",
      ToolCardDesc:
        "Get the latest news coverage, media content, and price events across all tradable assets. Use the buzz meter to show the volume of social media chatter and news about your favourite tradable assets.",
      toolCard_icon: marketBuzz,
    },
    {
      ToolCardTitle: "Technical Insights",
      ToolCardDesc:
        "Get all the latest insights on all trading products charts with proper pattern and events indicators. Use our screener tool to grasp product details and technical events before making trade decisions.",
      toolCard_icon: techInsights,
    },
    {
      ToolCardTitle: "Featured Ideas",
      ToolCardDesc:
        "Gain access to a wide range of trade concepts encompassing both technical and fundamental analysis derived from proven, tried-and-tested strategies for your favourite assets and technical setups. Easily filter trade concepts based on your preferred Forex pairs and technical analysis style, and monitor real-time trade setups as they unfold.",
      toolCard_icon: featuredIdeas,
    },
  ];
  return (
    <>
      <SideBar />
      <div className="container-fluid page-body-wrapper">
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="row justify-content-center">
              {/* <div className="page-header mb-4" style={{ marginLeft: "38rem" }}>
                <h3 className="page-title">Pro Trader Tool</h3>
              </div> */}
              <div className="col-md-8 grid-margin stretch-card">
                <div className="card p-4">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="containerLeft ">
                      <div className="mb-0">
                        Unlock ProTrader Tools and video tutorials now by
                        funding a minimum of USD$200.
                      </div>
                    </div>
                    <div className="containerRight">
                      <button className="text-black border rounded  py-2">
                        Deposit Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-10 grid-margin">
                <div className="d-flex flex-wrap justify-content-center ToolCards gap-4">
                  {ToolsCard.map((element, key) => (
                    <div
                      className="card p-4"
                      key={key}
                      style={{ maxWidth: "85%" }}
                    >
                      <div>
                        <h3 className="fw-normal mb-3">
                          {element.ToolCardTitle}
                        </h3>
                        <p className="fw-light">{element.ToolCardDesc}</p>
                      </div>
                      <div className="d-flex justify-content-end">
                        <img src={element.toolCard_icon} alt="toolIcons" />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
