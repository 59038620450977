import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { FiEdit3 } from "react-icons/fi";
import { updateUserInfo } from "../../redux/userSlice";
import { fetchCountryCodes } from "../../redux/countrySlice";
import { Link } from "react-router-dom";
import "./Loader.css";

const API_BASE_URL = "https://crmapi.kozeglobal.com/api";
const UPDATE_USER = `${API_BASE_URL}/update_user.php`;

export default function PersonalInformation() {
  const [iseditable, setIsEditable] = useState(false);
  const [inputFields, setInputFields] = useState({
    name: "",
    email: "",
    mobile: "",
    dob: "",
    country: "",
  });
  const [userImg, setUserImg] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editImageMode, setEditImageMode] = useState(false);

  const dispatch = useDispatch();
  const countries = useSelector((state) => state.country.countries);
  const user = useSelector((state) => state.user);
  const token = localStorage.getItem("userToken");
  const userData = user.userInfo;

  const fetchCountries = useCallback(async () => {
    try {
      await dispatch(fetchCountryCodes());
    } catch (error) {
      console.error("Error fetching country codes:", error);
      toast.error("Failed to load countries.");
    } finally {
      setLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    setInputFields({
      name: userData.name || "",
      email: userData.email || "",
      mobile: userData.mobile || "",
      dob: userData.dob || "",
      country: userData.country || "",
    });
  }, [userData]);

  useEffect(() => {
    // fetchUserData();
    fetchCountries();
  }, [fetchCountries]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputFields((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setSelectedImage(e.target.files[0]);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!iseditable) {
      setIsEditable(true);
      return;
    }

    const formData = new FormData();
    formData.append("name", inputFields.name);
    formData.append("dob", inputFields.dob);
    formData.append("country", inputFields.country);
    formData.append("token", token);
    if (selectedImage) {
      formData.append("user_img", selectedImage);
    }
    console.log("FormData:", Object.fromEntries(formData));

    if (!token) {
      toast.error("Invalid or expired token. Please log in again.");
      return;
    }

    try {
      setShowLoader(true);

      const response = await axios.post(UPDATE_USER, formData);
      console.log("Server response:", response.data);

      if (response.data.status === 69 || response.data.status === 400) {
        toast.error(
          response.data.result || "Failed to update personal information."
        );
        return;
      }

      dispatch(updateUserInfo(response.data));
      toast.success("Personal information updated successfully!");

      setInputFields({
        name: response.data.name,
        email: response.data.email,
        mobile: response.data.mobile,
        dob: response.data.dob,
        country: response.data.country,
      });
      setUserImg(response.data.data.response.user_img || "");

      setIsEditable(false);

      // Close the modal after submission
      closeModal();
    } catch (error) {
      console.error("Update failed:", error);
      toast.error("Failed to update personal information.");
    } finally {
      setShowLoader(false);
    }
  };

  const openModal = (mode) => {
    setEditImageMode(mode === "edit");
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedImage(null);
  };

  const InputArray = [
    {
      labelContent: "Name",
      inputType: "text",
      inputId: "realProfileEditname",
      inputName: "name",
      inputPlaceholder: "Full Name",
      value: inputFields.name,
      isDisabled: !iseditable,
    },
    {
      labelContent: "Email",
      inputType: "email",
      inputId: "realProfileEditPortalUser",
      inputName: "email",
      inputPlaceholder: "Email",
      value: inputFields.email,
      isDisabled: true,
    },
    {
      labelContent: "Mobile Number",
      inputType: "text",
      inputId: "realProfileEditMobileNumber",
      inputName: "mobile",
      inputPlaceholder: "Mobile Number",
      value: inputFields.mobile,
      maxlength: 10,
      isDisabled: true,
    },
    {
      labelContent: "Date of Birth",
      inputType: "date",
      inputId: "realProfileEditBirthDate",
      inputName: "dob",
      inputPlaceholder: "Date",
      value: inputFields.dob,
      isDisabled: !iseditable,
    },
    {
      labelContent: "Country",
      inputType: "select",
      inputId: "realProfileEditCountry",
      inputName: "country",
      inputPlaceholder: "Country",
      value: inputFields.country,
      isDisabled: !iseditable,
      selectOptions: countries,
    },
  ];

  return (
    <div className="container">
      <div className="row justify-content-center">
        {loading ? (
          <div className="col-12 text-center loader-container">
            <span className="loader"></span>
            <div className="loader-message">
              Wait, your data is getting updated...
            </div>
          </div>
        ) : (
          <div className="col-12 grid-margin stretch-card">
            <div>
              <div className="row mb-8">
                <div className="col-sm-12 col-md-6 mb-3 section-profile-summary">
                  <div className="card user-information text-center h-100">
                    <div className="card-body">
                      <div className="bg-circle"></div>
                      <div className="position-relative pofile mt-8">
                        <div
                          className="text-secondary profile-image rounded-circle justify-content-center align-items-center d-flex with-initals shadow"
                          onClick={() => openModal("view")}
                        >
                          <img
                            src={userImg}
                            alt="Profile"
                            style={{
                              height: "5rem",
                              width: "5rem",
                              borderRadius: "50%",
                              objectFit: "cover",
                              cursor: "pointer",
                            }}
                          />
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              openModal("edit");
                            }}
                            style={{
                              position: "absolute",
                              top: "60px",
                              left: "75px",
                              borderRadius: "50%",
                              padding: "2px",
                              cursor: "pointer",
                              color: "black",
                              width: "30px",
                              height: "30px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <FiEdit3 style={{ fontSize: "1rem" }} />
                          </div>
                        </div>
                      </div>
                      <h3 className="mb-4 mt-4 font-weight-bold">
                        {inputFields.name}
                      </h3>
                    </div>
                    <div className="card-footer p-3 d-flex align-items-center justify-content-center gap-3">
                      <Link
                        to="/reset-password"
                        className="btn text-black btn-rounded btn-sm mr-2 mr-xl-2 mb-xl-0 d-lg-block border"
                      >
                        Reset Password
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 mb-3 section-profile-fields">
                  <div className="card card-light h-100">
                    <div className="card-header d-flex align-items-center justify-content-between">
                      <h4 className="card-title">Personal Information</h4>
                    </div>
                    <div className="card-body p-3">
                      <form name="realProfileEdit" onSubmit={handleFormSubmit}>
                        <div className="form-row">
                          {InputArray.map((element, index) => (
                            <div
                              className="form-group col-md-6"
                              key={`${element.inputId}-${index}`}
                            >
                              <label htmlFor={element.inputId}>
                                {element.labelContent}
                              </label>
                              {element.inputType !== "select" ? (
                                <input
                                  type={element.inputType}
                                  className="form-control uniform-input" // Added class for uniform size
                                  id={element.inputId}
                                  name={element.inputName}
                                  placeholder={element.inputPlaceholder}
                                  value={element.value}
                                  onChange={handleInputChange}
                                  disabled={element.isDisabled}
                                  maxLength={element.maxlength}
                                />
                              ) : (
                                <select
                                  className="form-control uniform-input" // Added class for uniform size
                                  id={element.inputId}
                                  name={element.inputName}
                                  value={element.value}
                                  onChange={handleInputChange}
                                  disabled={element.isDisabled}
                                >
                                  <option value="" disabled>
                                    {element.inputPlaceholder}
                                  </option>
                                  {element.selectOptions.map((country) => (
                                    <option
                                      key={country.countryId}
                                      value={country.countryId}
                                    >
                                      {country.countryName}
                                    </option>
                                  ))}
                                </select>
                              )}
                            </div>
                          ))}
                        </div>
                        <div className="form-group">
                          <button
                            type="submit"
                            className="btn text-black border rounded"
                          >
                            {iseditable ? "Save Changes" : "Edit"}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {showLoader && (
          <div className="col-12 text-center loader-container">
            <span className="loader"></span>
            <div className="loader-message">
              Updating personal information...
            </div>
          </div>
        )}

        {/* Single Modal for Image View and Edit */}
        <div
          className={`modal fade ${modalIsOpen ? "show" : ""}`}
          id="imageModal"
          tabIndex="-1"
          aria-labelledby="imageModalLabel"
          aria-hidden={!modalIsOpen}
          style={{ display: modalIsOpen ? "block" : "none" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="imageModalLabel">
                  {editImageMode ? "Edit Profile Image" : "View Profile Image"}
                </h5>
                <button
                  type="button"
                  className="btn-close text-white"
                  aria-label="Close"
                  onClick={closeModal}
                >
                  X
                </button>
              </div>
              <div className="modal-body">
                {editImageMode ? (
                  <form onSubmit={handleFormSubmit}>
                    <div className="mb-3">
                      <label htmlFor="fileInput" className="form-label">
                        Select New Image
                      </label>
                      <input
                        type="file"
                        id="fileInput"
                        accept="image/*"
                        className="form-control"
                        onChange={handleImageChange}
                      />
                    </div>
                    <div className="d-grid">
                      <button
                        type="submit"
                        className="btn "
                        disabled={!selectedImage}
                      >
                        Update Image
                      </button>
                    </div>
                  </form>
                ) : (
                  <div className="text-center">
                    <img
                      src={userImg}
                      alt="Profile"
                      className="img-fluid rounded"
                      style={{
                        maxHeight: "400px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="modal-footer">
                {!editImageMode && (
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={closeModal}
                  >
                    Close
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
