import React, { useState, useRef, useEffect } from "react";
import SideBar from "./sideBar";
import "../assets/css/myProfile.css";
import PersonalInformation from "./MyProfile/PersonalInformation";
import UploadedDocs from "./MyProfile/UploadedDocs";
import ChangeMobileNo from "./MyProfile/ChangeMobileNo";
import Authentication from "./MyProfile/Auth";
import { updateLoginTimestamp } from "../redux/loginSlice";
import { useDispatch } from "react-redux";

export default function MyProfile() {
  const [defaultTab, setDefaultTab] = useState(0);
  const tabsRef = useRef(null);
  const tabRefs = useRef([]);
  const dispatch = useDispatch();

  const updateTimestamp = () => {
    const currentTime = new Date().getTime();
    localStorage.setItem("loginTimestamp", currentTime.toString());
    dispatch(updateLoginTimestamp());
  };

  const TabButton = [
    { text: "Personal information" },
    { text: "Uploaded Documents" },
    { text: "Email & Mobile No" },
    { text: "2FA " },
  ];

  const handleTab = (index) => {
    setDefaultTab(index);
    scrollToTab(index);
    updateTimestamp();
  };

  const scrollToTab = (index) => {
    if (tabRefs.current[index]) {
      tabRefs.current[index].scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (tabsRef.current) {
        if (window.innerWidth < 850) {
          tabsRef.current.classList.add("flex-nowrap", "overflow-auto");
        } else {
          tabsRef.current.classList.remove("flex-nowrap", "overflow-auto");
        }
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    scrollToTab(defaultTab);

    return () => window.removeEventListener("resize", handleResize);
  }, [defaultTab]);

  return (
    <>
      <SideBar />
      <div
        className="container-fluid page-body-wrapper"
        id="PersonalInformation"
      >
        <div className="main-panel ">
          <div className="content-wrapper">
            <div className="pt-2" style={{ margin: "0 auto" }}>
              <div className="d-flex tab" ref={tabsRef}>
                <div className="tab-container d-flex ">
                  {TabButton.map((element, key) => (
                    <div
                      className="flex-shrink-0"
                      key={key}
                      ref={(el) => (tabRefs.current[key] = el)}
                    >
                      <button
                        className={`${
                          key === defaultTab ? "is-active-tab" : "btn"
                        } btn text-black fs-5 fs-md-4 fs-lg-2 bg-white`}
                        onClick={() => handleTab(key)}
                      >
                        {element.text}
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-12 mt-2 d-flex tab-container">
              <div
                className="mt-4 rounded justify-content-center"
                style={{ width: "100%" }}
              >
                {defaultTab === 0 ? (
                  <PersonalInformation />
                ) : defaultTab === 1 ? (
                  <UploadedDocs />
                ) : defaultTab === 2 ? (
                  <ChangeMobileNo />
                ) : (
                  <Authentication />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
