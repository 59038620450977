import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { loginRequest, loginFailure } from "../redux/loginSlice";
import { setUserToken } from "../redux/userSlice";
import "../assets/css/login-register.css";
import LoginImg from "../img/Login_illustrator.svg";
import Logo from "../img/logo.png";
import axios from "axios";

const API_BASE_URL = "https://crmapi.kozeglobal.com/api/";
const FORGOT = `${API_BASE_URL}forgotpassword.php`;

export default function Forgot() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isDisabled, setIsDisabled] = useState(false);
  const [email, setEmail] = useState("");

  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      toast.error("Please enter your email address.");
      return;
    }

    try {
      dispatch(loginRequest());
      setIsDisabled(true);

      const response = await axios.post(FORGOT, { email });
      console.log(response);
      const apiResponse = response.data.data;
      console.log(apiResponse);

      if (apiResponse.status === 200) {
        const { token = "default_token", authType } =
          apiResponse.response || {};

        toast.success(
          "Your login credentials sent on your mail please check inbox / spam folder"
        );

        dispatch(setUserToken(token));
        localStorage.setItem("userToken", JSON.stringify(token));
        localStorage.setItem("sessionTime", Date.now().toString());

        switch (authType) {
          case 0:
            navigate("/", { replace: true });
            break;
          default:
            break;
        }
      } else {
        const errorMsg = apiResponse.result || "Something went wrong!";
        dispatch(loginFailure(errorMsg));
        toast.error(errorMsg);
      }
    } catch (error) {
      dispatch(loginFailure("Server Error !!"));
      toast.error(error.response?.data?.message || "Server Error !!");
    } finally {
      setIsDisabled(false);
    }
  };

  return (
    <section id="login">
      <div style={{ height: "100vh" }} className="login-left-side">
        <div className="stretch-card">
          <div className="main-card flex-column flex-sm-row">
            <div className="card-illustrator">
              <img
                src={LoginImg}
                className="Illustrator"
                alt="Login Illustration"
              />
            </div>
            <div className="card-body">
              <div className="head">
                <img src={Logo} className="brand-logo" alt="Logo" />
                <h4 className="description">
                  Please enter the Login email associated with your account and
                  we will email your password.
                </h4>
              </div>
              <form className="forms-sample mt-5" onSubmit={handleFormSubmit}>
                <div className="form-group">
                  <label htmlFor="email">Login Email</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Email"
                    onChange={handleInputChange}
                    value={email}
                    required
                  />
                </div>
                <div className="text-center mt-4">
                  <button
                    type="submit"
                    className="btn btn-primary m-2 d-block w-100"
                    disabled={isDisabled}
                  >
                    Forgot Password
                  </button>
                  <button
                    className="btn btn-primary m-2 d-block w-100"
                    disabled={isDisabled}
                    onClick={() => navigate("/")}
                  >
                    Back
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
