import { useState } from "react";
import SideBar from "./sideBar";
import logo from "../img/favicon.png";
import { Wheel } from "react-custom-roulette";
import "../assets/css/Wheel.css";
import { Link } from "react-router-dom";
import Reward from "../img/spinWheel.jpg";

const data = [
  { option: "0" },
  { option: "1" },
  { option: "2" },
  { option: "3" },
  { option: "4" },
  { option: "5" },
  { option: "6" },
  { option: "7" },
  { option: "8" },
  { option: "9" },
  { option: "10" },
];
export default function WheelSpin() {
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);

  const handleSpinClick = () => {
    if (!mustSpin) {
      const newPrizeNumber = Math.floor(Math.random() * data.length);
      setPrizeNumber(newPrizeNumber);
      setMustSpin(true);
    }
  };

  return (
    <>
      <SideBar />
      <div className="container-fluid page-body-wrapper" id="WheelSpin">
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="reward-points">
              <div className="row g-3">
                <div className="col-12 col-lg-8">
                  <div
                    className="card h-100 d-flex justify-content-center p-4"
                    style={{
                      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${Reward})`,
                      objectFit: "contain",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                    }}
                  >
                    <h3 style={{ color: "white" }}>KOZE GLOBAL Rewards</h3>
                    <p className="mb-0" style={{ color: "white" }}>
                      Reap more rewards as you trade
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="card h-100">
                    <div className="card-body p-3">
                      <p className="d-flex align-items-center gap-2">
                        <img
                          src={logo}
                          alt=""
                          className="img-fluid"
                          style={{ maxWidth: "30px" }}
                        />
                        <span>K-Points</span>
                        <i className="bi bi-info-circle-fill"></i>
                      </p>
                      <h1>0</h1>
                      <p className="m-0">
                        Make your first deposit to unlock redemptions
                      </p>
                    </div>
                    <div className="card-footer p-3 d-flex justify-content-between align-items-center">
                      <Link to="#" className="text-decoration-none text-black">
                        Terms and Conditions
                      </Link>
                      <i className="bi bi-chevron-right"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card p-4 p-md-5 mt-4">
              <div className="row g-4 align-items-center">
                <div className="col-12 col-lg-6 order-2 order-lg-1">
                  <h1 className="mb-4">Wheel of Fortune</h1>
                  <p className="fs-5">
                    Feeling lucky? Try your luck and win various surprises to
                    turbocharge your trading!
                  </p>
                  <p className="fs-5">
                    You are entitled to 1 free entry daily. You may use 100
                    K-Points to play again, limited to 5 additional times per
                    day.
                  </p>
                  <p className="fw-light">
                    Last step, deposit now to start spin and win.
                  </p>
                  <button
                    onClick={handleSpinClick}
                    className="btn btn-lg text-black text-black border rounded-4 w-100 mt-3"
                  >
                    SPIN NOW
                  </button>
                </div>
                <div className="col-12 col-lg-6 order-1 order-lg-2 d-flex justify-content-center align-items-center">
                  <div style={{ maxWidth: "100%", overflow: "hidden" }}>
                    <Wheel
                      mustStartSpinning={mustSpin}
                      prizeNumber={prizeNumber}
                      spinDuration={0.3}
                      fontFamily={"Rubik"}
                      fontWeight={400}
                      radiusLineWidth={0.2}
                      outerBorderWidth={1.5}
                      data={data}
                      textColors={["#000"]}
                      backgroundColors={[
                        "#FF8000",
                        "#F4C430",
                        "gray",
                        "lightblue",
                        "lightgreen",
                        "yellow",
                        "cyan",
                        "magenta",
                        "lime",
                        "violet",
                        "lightpink",
                      ]}
                      onStopSpinning={() => {
                        setMustSpin(false);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
