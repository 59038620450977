import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import SideBar from "./sideBar";
import "../assets/css/depositeFund.css";
import bnb from "../img/chain-bnb-logo.png";
import tron from "../img/chain-tron-logo.png";
import polygon from "../img/chain-polygon-logo.png";
import eth from "../img/chain-eth-logo.png";
import bankDeposit from "../img/bank-deposit.png";
import cashDeposit from "../img/cash-deposit.png";
import DepositFromBank from "./Deposit/DepositFromBank";
import DepositFormCash from "./Deposit/DepositFormCash";
import DepositFormCrypto from "./Deposit/DepositFormCrypto";
import { useSelector } from "react-redux";
import { Card } from "react-bootstrap";

const BASE_URL = "https://crmapi.kozeglobal.com/api/";
const POLY = `${BASE_URL}generate_wallet_polygon.php`;
const BINANCE = `${BASE_URL}generate_wallet_binance.php`;
const TRON = `${BASE_URL}generate_wallet_tron.php`;
const ETH = `${BASE_URL}generate_wallet_ethereum.php`;

export default function DepositFunds() {
  const [defaultDeposit, setDefaultDeposit] = useState(0);
  const [walletAddress, setWalletAddress] = useState("");
  const [qrCode, setQrCode] = useState("");
  const [currentBalance, setCurrentBalance] = useState(null);
  const token = localStorage.getItem("userToken");

  const currBlnc = useSelector((state) => state.balance.currentBalance);
  console.log(currBlnc);

  useEffect(() => {
    setCurrentBalance(currBlnc);
  }, [currBlnc]);

  const [selectedCard, setSelectedCard] = useState(null);
  const cardRefs = useRef([]);

  const DepositType = [
    {
      type: "Deposit with bank transfer",
      img: bankDeposit,
    },
    {
      type: "Deposit with Cash",
      img: cashDeposit,
    },
    {
      type: "USDT-BEP20",
      img: bnb,
      apiEndpoint: BINANCE,
    },
    {
      type: "USDT-TRC20",
      img: tron,
      apiEndpoint: TRON,
    },
    {
      type: "USDT-ETH20",
      img: polygon,
      apiEndpoint: POLY,
    },
    {
      type: "USDT-MATIC20",
      img: eth,
      apiEndpoint: ETH,
    },
  ];

  const HandleDepositType = (index) => {
    setDefaultDeposit(index);
    setSelectedCard(index);

    // Scroll to the selected card
    if (cardRefs.current[index]) {
      cardRefs.current[index].scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }

    const selectedDeposit = DepositType[index];
    if (selectedDeposit.apiEndpoint) {
      axios
        .post(selectedDeposit.apiEndpoint, { token })
        .then((response) => {
          const data = response.data.data.response;
          setWalletAddress(data.walletaddress || "");
          setQrCode(data.walletscanima || "");
        })
        .catch((error) => {
          console.error("API call error:", error);
          alert(
            "An error occurred while fetching the wallet address. Please try again."
          );
        });
    }
  };

  return (
    <>
      <SideBar />
      <div className="container-fluid page-body-wrapper" id="DepositFunds">
        <div className="main-panel">
          <div className="content-wrapper">
            <Card className="deposite-window p-md-3 bg-light">
              <div className="text-black">
                <h3 className="funds m-3">Deposit Funds</h3>
              </div>
              <Card.Body>
                <div className="row">
                  <div className="col-md-3">
                    <div className="DepositType d-flex flex-md-column gap-2 pointer">
                      {DepositType.map((element, key) => (
                        <Card
                          key={element.type}
                          ref={(el) => (cardRefs.current[key] = el)}
                          className={`deposit_channel cursor-move ${
                            key === defaultDeposit ? "active-channel" : ""
                          }`}
                          onClick={() => HandleDepositType(key)}
                        >
                          <Card.Body>
                            <div className="d-flex align-items-center justify-content-between text-decoration-none">
                              <div className="title">{element.type}</div>
                              <div className="logo logo_flex creditOrDebit_icon">
                                <img src={element.img} alt={element.type} />
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      ))}
                    </div>
                  </div>
                  <div className="col-md-9 mt-2 mt-md-0 d-flex justify-content-center">
                    <div className="channel bg-white p-3 rounded shadow-sm">
                      {defaultDeposit === 0 ? (
                        <DepositFromBank balance={currentBalance} />
                      ) : defaultDeposit === 1 ? (
                        <DepositFormCash balance={currentBalance} />
                      ) : (
                        <DepositFormCrypto
                          walletAddress={walletAddress}
                          qrCode={qrCode}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}
