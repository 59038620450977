import React, { useState, useEffect } from "react";
import axios from "axios";
import SideBar from "./sideBar";
import "../assets/css/TransferBetweenAccount.css";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { updateLoginTimestamp } from "../redux/loginSlice";

const BASE_API = "https://crmapi.kozeglobal.com/api/";
const ACCOUNT_LIST = `${BASE_API}mt5_accounts_list.php`;
const TRANSFER = `${BASE_API}wallet_to_mt5_api.php`;
const AMOUNT = `${BASE_API}get_current_wallet_api.php`;

export default function WalletMT5() {
  const [accounts, setAccounts] = useState([]);
  const [fromAccount, setFromAccount] = useState("");
  const [displayAmount, setDisplayAmount] = useState("");
  const [amount, setAmount] = useState("");
  const [note, setNote] = useState("");
  const token = localStorage.getItem("userToken");

  const dispatch = useDispatch();

  useEffect(() => {
    const updateTimestamp = () => {
      const currentTime = new Date().getTime();
      localStorage.setItem("loginTimestamp", currentTime.toString());
      dispatch(updateLoginTimestamp());
    };

    updateTimestamp();
  }, [dispatch]);

  useEffect(() => {
    if (!token) {
      toast.error("User token is missing. Please log in again.");
      return;
    }

    const fetchAccounts = async () => {
      try {
        const response = await axios.post(ACCOUNT_LIST, { token });
        console.log(response);
        if (response.data.data && response.data.data.response) {
          setAccounts(response.data.data.response);
        } else {
          toast.error("Failed to load accounts");
        }
      } catch (err) {
        console.error("Failed to fetch accounts", err);
        toast.error("Failed to load accounts");
      }
    };

    fetchAccounts();
  }, [token]);

  useEffect(() => {
    if (!token) return;

    const fetchAmount = async () => {
      try {
        const response = await axios.post(AMOUNT, { token });
        console.log(response);
        if (response.data.data && response.data.data.response) {
          setDisplayAmount(response.data.data.response.current_bal || "0");
        } else {
          toast.error("Failed to load wallet amount");
        }
      } catch (err) {
        console.error("Failed to fetch wallet amount", err);
        toast.error("Failed to load wallet amount");
      }
    };

    fetchAmount();
  }, [token]);

  const fetchAmount = async () => {
    try {
      const response = await axios.post(AMOUNT, { token });
      console.log(response);
      if (response.data.data && response.data.data.response) {
        setDisplayAmount(response.data.data.response.current_bal || "0");
      } else {
        toast.error("Failed to load wallet amount");
      }
    } catch (err) {
      console.error("Failed to fetch wallet amount", err);
      toast.error("Failed to load wallet amount");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!fromAccount || !amount || !note) {
      toast.error("Please fill all fields");
      return;
    }

    const formData = {
      mt5accountselect: fromAccount,
      amount,
      note,
      token,
    };

    try {
      const response = await axios.post(TRANSFER, formData);
      console.log(response);
      if (response.data.data.result) {
        toast.success("Transfer successful!");
        setFromAccount("");
        setAmount("");
        setNote("");
        await fetchAmount();
      } else {
        // More specific error message
        toast.error(
          response.data.data.message || "Transfer failed. Please try again."
        );
      }
    } catch (err) {
      console.error("Submission error", err);
      toast.error(
        err.response?.data?.message || "An error occurred during the transfer"
      );
    }
  };

  return (
    <>
      <SideBar />
      <div
        className="container-fluid page-body-wrapper"
        id="TransferBetweenAccount"
      >
        <div className="main-panel">
          <div className="content-wrapper">
            <div
              className="row justify-content-center"
              style={{ marginTop: "2rem" }}
            >
              <div
                className="col-12 col-md-10 col-lg-8"
                style={{ width: "920px" }}
              >
                <div className="text-black">
                  <h3 className="funds m-3">Wallet To MT5 </h3>
                </div>
                <div className="card">
                  <div className="card-body ">
                    <form
                      id="mt5_to_mt5_transfer"
                      name="mt5_to_mt5_transfer"
                      autoComplete="off"
                      onSubmit={handleSubmit}
                    >
                      <div className="form-group mt-3">
                        <label htmlFor="amount">Amount:</label>
                        <input
                          type="number"
                          id="amount"
                          name="amount"
                          className="form-control"
                          value={amount}
                          onChange={(e) => setAmount(e.target.value)}
                          required
                        />
                        <p className="text-black ">
                          Wallet Balance is: {displayAmount} USD
                        </p>
                      </div>
                      <div className="form-group mt-3">
                        <label htmlFor="mt5accountselectfrom">
                          To MT5 Account:
                        </label>
                        <select
                          name="mt5accountselectfrom"
                          id="mt5accountselectfrom"
                          className="form-control"
                          value={fromAccount}
                          onChange={(e) => setFromAccount(e.target.value)}
                        >
                          <option value="">Select Account</option>
                          {accounts.map((account) => (
                            <option key={account.id} value={account.id}>
                              {account.id}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="form-group mt-3">
                        <label htmlFor="note">Note:</label>
                        <input
                          type="text"
                          id="note"
                          name="note"
                          className="form-control"
                          value={note}
                          onChange={(e) => setNote(e.target.value)}
                          required
                        />
                      </div>
                      <input
                        type="hidden"
                        name="type"
                        id="type"
                        value="mt5_to_mt5_balance"
                      />
                      <div className="form-group mt-2 text-center">
                        <button
                          type="submit"
                          id="submit"
                          className="btn text-black border rounded"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
