import { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";

const API_BASE_URL = "https://crmapi.kozeglobal.com/api";
const UPDATE_EMAIL_MOB = `${API_BASE_URL}/update_only_others.php`;

export default function ChangeMobileNo() {
  const [emailInputFields, setEmailInputFields] = useState({
    email: "",
    confirmemail: "",
  });
  const [originalEmailFields, setOriginalEmailFields] = useState({
    email: "",
    confirmemail: "",
  });
  const [mobileno, setMobileno] = useState("");
  const [originalMobileno, setOriginalMobileno] = useState("");
  const [isEmailEditable, setIsEmailEditable] = useState(false);
  const [isMobileEditable, setIsMobileEditable] = useState(false);
  const token = localStorage.getItem("userToken");

  // Handle input changes for email fields
  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    setEmailInputFields({
      ...emailInputFields,
      [name]: value,
    });
  };

  // Handle input change for mobile number
  const handleMobileNumber = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setMobileno(value);
  };

  // Save email changes
  const handleEmailFormSubmit = async (e) => {
    e.preventDefault();

    if (emailInputFields.email !== emailInputFields.confirmemail) {
      toast.error("Emails did not match!");
      return;
    }

    // Add email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(emailInputFields.email)) {
      toast.error("Please enter a valid email address");
      return;
    }

    try {
      const response = await axios.post(UPDATE_EMAIL_MOB, {
        token,
        email: emailInputFields.email,
      });
      console.log(response);
      toast.success("Email Updated Successfully!");
      setIsEmailEditable(false);
      setOriginalEmailFields(emailInputFields);
    } catch (error) {
      console.error("Error updating email:", error);
      toast.error(
        error.response?.data?.message ||
          "Error updating email. Please try again."
      );
    }
  };

  // Save mobile number changes
  const handleMobileNumberForm = async () => {
    if (mobileno.length < 10) {
      toast.error("Mobile Number should be of length 10");
      return;
    }

    try {
      const response = await axios.post(UPDATE_EMAIL_MOB, {
        token,
        mobileno,
      });
      console.log(response);
      toast.success("Mobile Number Updated Successfully");
      setIsMobileEditable(false);
    } catch (error) {
      console.error("Error updating mobile number:", error);
      toast.error("Error updating mobile number. Please try again.");
    }
  };

  // Cancel email changes
  const handleEmailCancel = () => {
    setEmailInputFields(originalEmailFields);
    setIsEmailEditable(false);
  };

  // Cancel mobile number changes
  const handleMobileNumberCancel = () => {
    setMobileno(originalMobileno);
    setIsMobileEditable(false);
  };

  // Start editing email fields
  const handleEmailEdit = () => {
    setOriginalEmailFields(emailInputFields);
    setIsEmailEditable(true);
  };

  // Start editing mobile number
  const handleMobileEdit = () => {
    setOriginalMobileno(mobileno);
    setIsMobileEditable(true);
  };

  // Define form fields
  const EmailArray = [
    {
      itemLabelContent: "New Email",
      type: "email",
      name: "email",
      value: emailInputFields.email,
      placeholder: "New Email",
      disabled: !isEmailEditable,
    },
    {
      itemLabelContent: "Confirm Email",
      type: "email",
      name: "confirmemail",
      value: emailInputFields.confirmemail,
      placeholder: "Confirm Email",
      disabled: !isEmailEditable,
    },
  ];

  return (
    <div className="container my-4">
      <div className="row">
        {/* Email Change Section */}
        <div className="col-lg-6 col-md-12 mb-4">
          <div className="card">
            <form
              name="change_email_address"
              method="post"
              noValidate="noValidate"
              className="needs-validation"
              onSubmit={handleEmailFormSubmit}
            >
              <div className="card-header">
                <h4 className="card-title">Change Email</h4>
              </div>
              <div className="card-body p-3 text-black">
                {EmailArray.map((element, key) => (
                  <div className="form-group mb-3" key={key}>
                    <label className="required form-label">
                      {element.itemLabelContent}
                    </label>
                    <input
                      type={element.type}
                      placeholder={element.placeholder}
                      name={element.name}
                      value={element.value}
                      onChange={handleEmailChange}
                      required
                      autoComplete="off"
                      className="form-control"
                      maxLength="255"
                      disabled={element.disabled}
                    />
                  </div>
                ))}

                <div className="d-flex justify-content-between">
                  {!isEmailEditable ? (
                    <div
                      type="button"
                      className="btn text-black border"
                      onClick={handleEmailEdit}
                    >
                      Edit
                    </div>
                  ) : (
                    <>
                      <button
                        type="submit"
                        className="text-black border rounded me-2"
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        className="btn text-black border"
                        onClick={handleEmailCancel}
                      >
                        Cancel
                      </button>
                    </>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>

        {/* Mobile Number Change Section */}
        <div className="col-lg-6 col-md-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Change Mobile No.</h4>
            </div>
            <div className="card-body p-3 text-black">
              <p className="card-text">
                Please Enter your Mobile Number below.
              </p>
              <div className="mb-3">
                <input
                  type="text"
                  value={mobileno}
                  className="form-control"
                  maxLength={10}
                  placeholder="Enter 10 Digit mobile No."
                  onChange={handleMobileNumber}
                  disabled={!isMobileEditable}
                />
              </div>
              <div className="d-flex justify-content-between">
                {!isMobileEditable ? (
                  <button
                    type="button"
                    className="btn text-black border"
                    onClick={handleMobileEdit}
                  >
                    Edit
                  </button>
                ) : (
                  <>
                    <button
                      type="button"
                      className="text-black border rounded me-2"
                      onClick={handleMobileNumberForm}
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={handleMobileNumberCancel}
                    >
                      Cancel
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
