import React, { useState, useEffect } from "react";
import axios from "axios";
import SideBar from "./sideBar";
import "../assets/css/TransferBetweenAccount.css";
import { useDispatch } from "react-redux";
import { updateLoginTimestamp } from "../redux/loginSlice";

const BASE_API = "https://crmapi.kozeglobal.com/api/";
const ACCOUNT_LIST = `${BASE_API}mt5_accounts_list.php`;
const TRANSFER = `${BASE_API}mt5_to_mt5_tranfer_api.php`;

export default function TransferBetweenAccount() {
  const [accounts, setAccounts] = useState([]);
  const [fromAccount, setFromAccount] = useState("");
  const [toAccount, setToAccount] = useState("");
  const [amount, setAmount] = useState("");
  const [note, setNote] = useState("");
  const [error, setError] = useState("");
  const token = localStorage.getItem("userToken");

  const dispatch = useDispatch();

  useEffect(() => {
    const updateTimestamp = () => {
      const currentTime = new Date().getTime();
      localStorage.setItem("loginTimestamp", currentTime.toString());
      dispatch(updateLoginTimestamp());
    };

    updateTimestamp();
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(ACCOUNT_LIST, { token });
        setAccounts(response.data.data.response || []);
      } catch (err) {
        console.error("Failed to fetch accounts", err);
        setError("Failed to load accounts");
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    if (!fromAccount || !toAccount || !amount || !note) {
      setError("Please fill all fields");
      return;
    }

    if (fromAccount === toAccount) {
      setError("Cannot transfer to the same account");
      return;
    }

    if (parseFloat(amount) <= 0) {
      setError("Amount must be greater than 0");
      return;
    }

    const formData = {
      senderid: fromAccount,
      receiverid: toAccount,
      amount,
      note,
      token,
    };

    try {
      const response = await axios.post(TRANSFER, formData);
      if (response.data.status === "success") {
        // Handle success (e.g., show a success message, reset form, etc.)
        console.log("Transfer successful");
        // Reset form fields
        setFromAccount("");
        setToAccount("");
        setAmount("");
        setNote("");
      } else {
        setError(response.data.message || "Transfer failed");
      }
    } catch (err) {
      console.error("Submission error", err);
      setError("An error occurred during the transfer");
    }
  };

  return (
    <>
      <SideBar />
      <div
        className="container-fluid page-body-wrapper"
        id="TransferBetweenAccount"
      >
        <div className="main-panel">
          <div className="content-wrapper">
            <div
              className="row justify-content-center"
              style={{ marginTop: "2rem" }}
            >
              <div
                className="col-12 col-md-10 col-lg-8"
                style={{ width: "920px" }}
              >
                <div className="text-black">
                  <h3 className="funds m-3">Transfer Between Account</h3>
                </div>
                <div className="card">
                  <div className="card-body">
                    <form
                      id="mt5_to_mt5_transfer"
                      name="mt5_to_mt5_transfer"
                      autoComplete="off"
                      onSubmit={handleSubmit}
                    >
                      <div className="form-group mt-3">
                        <label htmlFor="mt5accountselectfrom">
                          From Account:
                        </label>
                        <select
                          name="mt5accountselectfrom"
                          id="mt5accountselectfrom"
                          className="form-control"
                          value={fromAccount}
                          onChange={(e) => setFromAccount(e.target.value)}
                        >
                          <option value="">Select Account</option>
                          {accounts && accounts.length > 0 ? (
                            accounts.map((account) => (
                              <option key={account.id} value={account.id}>
                                {account.id}
                              </option>
                            ))
                          ) : (
                            <option value="">No accounts available</option>
                          )}
                        </select>
                      </div>
                      <div className="form-group mt-3">
                        <label htmlFor="mt5accountselect">To Account:</label>
                        <select
                          name="mt5accountselect"
                          id="mt5accountselect"
                          className="form-control"
                          value={toAccount}
                          onChange={(e) => setToAccount(e.target.value)}
                        >
                          <option value="">Select Account</option>
                          {accounts && accounts.length > 0 ? (
                            accounts.map((account) => (
                              <option key={account.id} value={account.id}>
                                {account.id}
                              </option>
                            ))
                          ) : (
                            <option value="">No accounts available</option>
                          )}
                        </select>
                      </div>
                      <div className="form-group mt-3">
                        <label htmlFor="amount">Amount:</label>
                        <input
                          type="number"
                          id="amount"
                          name="amount"
                          className="form-control"
                          value={amount}
                          onChange={(e) => setAmount(e.target.value)}
                          required
                        />
                      </div>
                      <div className="form-group mt-3">
                        <label htmlFor="note">Note:</label>
                        <input
                          type="text"
                          id="note"
                          name="note"
                          className="form-control"
                          value={note}
                          onChange={(e) => setNote(e.target.value)}
                          required
                        />
                      </div>
                      <input
                        type="hidden"
                        name="type"
                        id="type"
                        value="mt5_to_mt5_balance"
                      />
                      <div className="form-group mt-2 text-center">
                        <button
                          type="submit"
                          id="submit"
                          className="btn text-black border rounded"
                        >
                          Submit
                        </button>
                      </div>
                      {error && (
                        <p className="text-danger text-center">{error}</p>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
