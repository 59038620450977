import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateLoginTimestamp } from "../../redux/loginSlice";

const API = "https://crmapi.kozeglobal.com/api/";
const URL = `${API}all_get_deal_report_history_filter.php`;

const DealReport = () => {
  const [fdate, setFdate] = useState("");
  const [edate, setEdate] = useState("");
  const [isFiltered, setIsFiltered] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [validationError, setValidationError] = useState("");
  const token = localStorage.getItem("userToken");

  const today = new Date().toISOString().split("T")[0];

  const dispatch = useDispatch();

  useEffect(() => {
    const updateTimestamp = () => {
      const currentTime = new Date().getTime();
      localStorage.setItem("loginTimestamp", currentTime.toString());
      dispatch(updateLoginTimestamp());
    };

    updateTimestamp();
  }, [dispatch]);

  const handleSubmit = async () => {
    if (!fdate || !edate) {
      setValidationError("Please select both start and end dates.");
      return;
    }

    if (new Date(fdate) > new Date(edate)) {
      setValidationError("End date must be after the start date.");
      return;
    }

    const requestData = {
      token,
      fdate,
      edate,
    };

    const response = await axios.post(URL, requestData);
    console.log(response);

    setValidationError("");
    setIsFiltered(true);
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const handleReset = () => {
    setFdate("");
    setEdate("");
    setIsFiltered(false);
  };

  const isSubmitButtonDisabled =
    !fdate || !edate || new Date(fdate) > new Date(edate);

  return (
    <div>
      <div className="card-body mt-4">
        <div className="row">
          <div className="col-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body mt-4">
                <div className="d-flex gap-1 justify-content-center align-items-center flex-wrap flex-md-nowrap">
                  <div className="form-group">
                    <label htmlFor="from">From</label>
                    <input
                      type="date"
                      className="form-control"
                      id="from"
                      value={fdate}
                      onChange={(e) => setFdate(e.target.value)}
                      max={today}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="to">To</label>
                    <input
                      type="date"
                      className="form-control"
                      id="to"
                      value={edate}
                      onChange={(e) => setEdate(e.target.value)}
                      max={today}
                    />
                  </div>

                  <button
                    className="btn text-black border rounded mt-2"
                    onClick={handleSubmit}
                    disabled={isSubmitButtonDisabled}
                  >
                    Submit
                  </button>
                  {isFiltered && (
                    <button
                      className="btn text-black border rounded mt-2 ms-2"
                      onClick={handleReset}
                    >
                      Reset
                    </button>
                  )}
                </div>
                {loading && <p>Loading...</p>}
                {error && <p className="text-danger">{error}</p>}
                {validationError && (
                  <p className="text-danger">{validationError}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DealReport;
