import React, { useEffect, useState, useRef } from "react";
import SideBar from "./sideBar";
import "../assets/css/tradingRoom.css";
import Overview from "./VIPTrading/Overview";
import Interactive from "./VIPTrading/Interactive";
import Eligibility from "./VIPTrading/Eligibility";
import VIPSupport from "./VIPTrading/VIPSupport";
import RequestResearch from "./VIPTrading/RequestResearch";
import LearnFromBest from "./VIPTrading/LernFromBest";

export default function VIPTradingRoom() {
  const [defaultTab, setDefaultTab] = useState(0);
  const tabsRef = useRef(null);
  const TabButton = [
    {
      text: "Overview",
    },
    {
      text: "Interactive Analysis",
    },
    {
      text: "Learn From the best",
    },
    {
      text: "Request research",
    },
    {
      text: "VIP Support",
    },
  ];

  const handleTab = (index) => {
    setDefaultTab(index);
    scrollToTab(index);
    // Ensure the tab container is scrollable
    if (tabsRef.current) {
      tabsRef.current.classList.add("overflow-auto");
    }
  };
  const scrollToTab = (index) => {
    if (tabsRef.current && tabsRef.current.children[index]) {
      const tabElement = tabsRef.current.children[index];
      const tabOffset =
        tabElement.offsetLeft +
        tabElement.offsetWidth / 2 -
        tabsRef.current.offsetWidth / 2 +
        tabsRef.current.scrollLeft; // Adjusted for scroll position
      tabsRef.current.scrollTo({
        left: tabOffset,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (tabsRef.current) {
        if (window.innerWidth < 850) {
          tabsRef.current.classList.add("flex-nowrap", "overflow-auto");
        } else {
          tabsRef.current.classList.remove("flex-nowrap", "overflow-auto");
        }
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    scrollToTab(defaultTab);

    return () => window.removeEventListener("resize", handleResize);
  }, [defaultTab]);

  return (
    <>
      <SideBar />
      <div className="container-fluid page-body-wrapper" id="VIPTradingRoom">
        <div className="main-panel">
          <div className="content-wrapper">
            <div class="row">
              <div style={{ width: "80%", margin: "0 auto" }}>
                <div
                  className="d-flex flex-nowrap ps-3 Account-Tab overflow-auto"
                  style={{
                    whiteSpace: "nowrap",
                  }}
                >
                  {TabButton.map((element, key) => (
                    <div className="me-2" key={key}>
                      <button
                        className={`${
                          key === defaultTab ? "is-active" : ""
                        } btn text-black`}
                        onClick={() => handleTab(key)}
                      >
                        {element.text}
                      </button>
                    </div>
                  ))}
                </div>
              </div>
              <div
                className="card p-3"
                style={{ width: "80%", margin: "0 auto" }}
              >
                <button className="btn text-black border rounded mx-auto mt-5 fs-6 px-4 py-3 rounded-4">
                  VIP ROOM LOGIN
                </button>
                {defaultTab === 0 ? (
                  <Overview />
                ) : defaultTab === 1 ? (
                  <Interactive />
                ) : defaultTab === 2 ? (
                  <LearnFromBest />
                ) : defaultTab === 3 ? (
                  <RequestResearch />
                ) : defaultTab === 4 ? (
                  <VIPSupport />
                ) : (
                  <Eligibility />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
