import { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import jQuery from "jquery";
import Header from "./header";
import logo from "../img/logo.png";
import logoHeader from "../img/favicon.png";
import { useSelector } from "react-redux";
import "../index.css";
import { TbFileReport } from "react-icons/tb";
import { BiSupport } from "react-icons/bi";
import { RiVipCrownLine, RiFundsBoxLine, RiToolsFill } from "react-icons/ri";
import { IoSpeedometerOutline } from "react-icons/io5";
import { CgProfile } from "react-icons/cg";
import { GrDownload, GrTrophy } from "react-icons/gr";

export default function SideBar() {
  const [userNames, setUserName] = useState("");
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (user.userInfo?.name) {
      let name = user.userInfo?.name;
      if (name.length > 8) {
        const firstSpaceIndex = name.indexOf(" ");

        if (firstSpaceIndex !== -1) {
          name = name.substring(0, firstSpaceIndex + 1) + "...";
        } else {
          name = name.substring(0, 8) + "...";
        }
      }
      setUserName(name);
    }
  }, [user.userInfo?.name]);

  useEffect(() => {
    // responsive sidebar jQuery
    (function ($) {
      $(function () {
        $('[data-toggle="offcanvas"]').on("click", function () {
          $(".sidebar-offcanvas").toggleClass("active");
        });
      });
    })(jQuery);

    // hover collaps menu
    (function ($) {
      //Open submenu on hover in compact sidebar mode and horizontal menu mode
      $(document).on(
        "mouseenter mouseleave",
        ".sidebar .nav-item",
        function (ev) {
          var body = $("body");
          var sidebarIconOnly = body.hasClass("sidebar-icon-only");
          var sidebarFixed = body.hasClass("sidebar-fixed");
          if (!("ontouchstart" in document.documentElement)) {
            if (sidebarIconOnly) {
              if (sidebarFixed) {
                if (ev.type === "mouseenter") {
                  body.removeClass("sidebar-icon-only");
                }
              } else {
                var $menuItem = $(this);
                if (ev.type === "mouseenter") {
                  $menuItem.addClass("hover-open");
                } else {
                  $menuItem.removeClass("hover-open");
                }
              }
            }
          }
        }
      );
    })(jQuery);

    // Expand sidebar jQuery
    (function ($) {
      $(function () {
        var body = $("body");

        var sidebar = $(".sidebar");

        function addActiveClass(element) {
          if (current === "") {
            if (element.attr("href").indexOf("index.html") !== -1) {
              element.parents(".nav-item").last().addClass("active");
              if (element.parents(".sub-menu").length) {
                element.closest(".collapse").addClass("show");
                element.addClass("active");
              }
            }
          } else {
            if (element.attr("href").indexOf(current) !== -1) {
              element.parents(".nav-item").last().addClass("active");
              if (element.parents(".sub-menu").length) {
                element.closest(".collapse").addClass("show");
                element.addClass("active");
              }
              if (element.parents(".submenu-item").length) {
                element.addClass("active");
              }
            }
          }
        }

        var current = window.location.pathname
          .split("/")
          .slice(-1)[0]
          .replace(/^\/|\/$/g, "");
        $(".nav li a", sidebar).each(function () {
          var $this = $(this);
          addActiveClass($this);
        });

        $(".horizontal-menu .nav li a").each(function () {
          var $this = $(this);
          addActiveClass($this);
        });

        sidebar.on("show.bs.collapse", ".collapse", function () {
          sidebar.find(".collapse.show").collapse("hide");
        });

        applyStyles();

        function applyStyles() {
          if (!body.hasClass("rtl")) {
            if (
              $(".settings-panel .tab-content .tab-pane.scroll-wrapper").length
            ) {
            }
            if ($(".chats").length) {
            }
            if (body.hasClass("sidebar-fixed")) {
            }
          }
        }

        $('[data-toggle="minimize"]').on("click", function () {
          if (
            body.hasClass("sidebar-toggle-display") ||
            body.hasClass("sidebar-absolute")
          ) {
            body.toggleClass("sidebar-hidden");
          } else {
            body.toggleClass("sidebar-icon-only");
          }
        });

        $(".form-check label,.form-radio label").append(
          '<i className="input-helper"></i>'
        );

        $("#fullscreen-button").on("click", function toggleFullScreen() {
          if (
            (document.fullScreenElement !== undefined &&
              document.fullScreenElement === null) ||
            (document.msFullscreenElement !== undefined &&
              document.msFullscreenElement === null) ||
            (document.mozFullScreen !== undefined && !document.mozFullScreen) ||
            (document.webkitIsFullScreen !== undefined &&
              !document.webkitIsFullScreen)
          ) {
            if (document.documentElement.requestFullScreen) {
              document.documentElement.requestFullScreen();
            } else if (document.documentElement.mozRequestFullScreen) {
              document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullScreen) {
              document.documentElement.webkitRequestFullScreen(
                Element.ALLOW_KEYBOARD_INPUT
              );
            } else if (document.documentElement.msRequestFullscreen) {
              document.documentElement.msRequestFullscreen();
            }
          } else {
            if (document.cancelFullScreen) {
              document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
              document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
              document.webkitCancelFullScreen();
            } else if (document.msExitFullscreen) {
              document.msExitFullscreen();
            }
          }
        });

        // Add click event listener to buttons
        $(".nav-link").on("click", function () {
          $(".nav-link").removeClass("active-button");
          $(this).addClass("active-button");
        });
      });
    })(jQuery);
  }, []);

  return (
    <>
      <Header />
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
          <Link
            className="sidebar-brand brand-logo"
            to="/home"
            style={{
              color: "#042742",
              textDecoration: "none",
              letterSpacing: "3px",
              textAlign: "center",
            }}
          >
            <img src={logo} alt="" style={{ width: "150px", height: "70px" }} />
          </Link>
          <Link
            className="sidebar-brand brand-logo-mini"
            to="/home"
            style={{ color: "#fff", textDecoration: "none", width: "100px" }}
          >
            <img src={logoHeader} alt="error-icon" style={{ width: "100px" }} />
          </Link>
        </div>
        <ul className="nav overflow-menu">
          <li className="nav-item profile">
            <div className="profile-desc">
              <div className="profile-pic">
                <div className="count-indicator">
                  <CgProfile
                    alt="circle"
                    style={{ color: "white", fontSize: "2rem" }}
                  />
                  <span
                    className="count bg-success"
                    style={{ fontSize: "2rem" }}
                  ></span>
                </div>
                <div className="profile-name">
                  <h5 className="mb-0 font-weight-normal text-white">
                    {userNames}
                  </h5>
                  <span style={{ color: "white" }}>Gold Member</span>
                </div>
              </div>
              <Link to="#" id="profile-dropdown" data-bs-toggle="dropdown">
                <i className="bi bi-three-dots-vertical"></i>
              </Link>
              <div
                className="dropdown-menu dropdown-menu-right sidebar-dropdown preview-list"
                aria-labelledby="profile-dropdown"
              >
                <Link to="/Profile" className="dropdown-item preview-item">
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-dark rounded-circle">
                      <i className="bi bi-gear-fill text-primary"></i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <p className="preview-subject ellipsis mb-1 text-small">
                      Account settings
                    </p>
                  </div>
                </Link>
                <div className="dropdown-divider"></div>
                <Link
                  to="/reset-password"
                  className="dropdown-item preview-item"
                >
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-dark rounded-circle">
                      <i className="bi bi-fingerprint text-info"></i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <p className="preview-subject ellipsis mb-1 text-small">
                      Change Password
                    </p>
                  </div>
                </Link>
                <div className="dropdown-divider"></div>
                <Link to="#" className="dropdown-item preview-item">
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-dark rounded-circle">
                      <i className="bi bi-check2-circle text-success"></i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <p className="preview-subject ellipsis mb-1 text-small">
                      To-do list
                    </p>
                  </div>
                </Link>
              </div>
            </div>
          </li>
          <li className="nav-item nav-category">
            <span className="nav-link" style={{ color: "white" }}>
              Navigation
            </span>
          </li>
          <li className="nav-item menu-items">
            <Link className="nav-link" to="/home">
              <span className="menu-icon">
                <IoSpeedometerOutline />
              </span>
              <span className="menu-title" style={{ color: "white" }}>
                Dashboard
              </span>
            </Link>
          </li>
          <li className="nav-item menu-items">
            <Link className="nav-link" to="/Profile">
              <span className="menu-icon">
                <CgProfile />
              </span>
              <span className="menu-title" style={{ color: "white" }}>
                Profile
              </span>
            </Link>
          </li>

          <li className="nav-item menu-items">
            <a
              className="nav-link active"
              data-bs-toggle="collapse"
              href="#ui-basic"
              aria-expanded="true"
              aria-controls="ui-basic"
            >
              <span className="menu-icon">
                <RiFundsBoxLine />
              </span>
              <span className="menu-title" style={{ color: "white" }}>
                Funds
              </span>
              <i className="bi bi-chevron-down chevron-hide fs-7 ps-2"></i>
            </a>
            <div className="collapse show" id="ui-basic">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/DepositeFunds"
                    style={{ color: "white" }}
                  >
                    Deposit Funds
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/WithdrawFunds"
                    style={{ color: "white" }}
                  >
                    Withdraw Funds
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/TransferBetweenAccounts"
                    style={{ color: "white" }}
                  >
                    Transfer Between Accounts
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/mt5account"
                    style={{ color: "white" }}
                  >
                    MT5 to Wallet
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/walletmt5"
                    style={{ color: "white" }}
                  >
                    Wallet to MT5
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          <li className="nav-item menu-items">
            <Link className="nav-link" to="/TransactionHistory">
              <span className="menu-icon">
                <TbFileReport />
              </span>
              <span className="menu-title" style={{ color: "white" }}>
                Report
              </span>
            </Link>
          </li>
          <li className="nav-item menu-items">
            <Link className="nav-link" to="/DownloadApp">
              <span className="menu-icon">
                <GrDownload />
              </span>
              <span className="menu-title" style={{ color: "white" }}>
                Download
              </span>
            </Link>
          </li>
          <li className="nav-item menu-items">
            <a
              className="nav-link"
              data-bs-toggle="collapse"
              href="#Tools"
              aria-expanded="false"
              aria-controls="Tools"
            >
              <span className="menu-icon">
                <RiToolsFill />
              </span>
              <span className="menu-title" style={{ color: "white" }}>
                Tools
              </span>
              <i className="bi bi-chevron-down chevron-hide fs-7 ps-2"></i>
            </a>
            <div className="collapse" id="Tools">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/ProTraderTool"
                    style={{ color: "white" }}
                  >
                    Pro Trader Tool
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/TechnicalAnalysis"
                    style={{ color: "white" }}
                  >
                    Technical Analysis
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/NationalVolumeCalculator"
                    style={{ color: "white" }}
                  >
                    National Volume Calculator
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          <li className="nav-item menu-items">
            <Link className="nav-link" to="/VIPTradingRoom">
              <span className="menu-icon">
                <RiVipCrownLine />
              </span>
              <span className="menu-title" style={{ color: "white" }}>
                VIP Trading Room
              </span>
            </Link>
          </li>

          <li className="nav-item menu-items">
            <a
              className="nav-link"
              data-bs-toggle="collapse"
              href="#Rewards"
              aria-expanded="false"
              aria-controls="Rewards"
            >
              <span className="menu-icon">
                <GrTrophy />
              </span>
              <span className="menu-title" style={{ color: "white" }}>
                Rewards
              </span>
              <i className="bi bi-chevron-down chevron-hide fs-7 ps-2"></i>
            </a>
            <div className="collapse" id="Rewards">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/RewardsOverview"
                    style={{ color: "white" }}
                  >
                    Overview
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/WheelSpin"
                    style={{ color: "white" }}
                  >
                    Wheel Spin
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/LuckyDraw"
                    style={{ color: "white" }}
                  >
                    Lucky Draw
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/CashRedemption"
                    style={{ color: "white" }}
                  >
                    Cash Redemption
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/VouchersRedemption"
                    style={{ color: "white" }}
                  >
                    Vouchers Redemption
                  </Link>
                </li>
              </ul>
            </div>
          </li>

          <li className="nav-item menu-items">
            <Link className="nav-link" to="/support">
              <span className="menu-icon">
                <BiSupport />
              </span>
              <span className="menu-title" style={{ color: "white" }}>
                Support
              </span>
            </Link>
          </li>
        </ul>
      </nav>
      <Outlet />
    </>
  );
}
