import React from "react";
import SideBar from "./sideBar";
import "../assets/css/rewards.css";
import logo from "../img/favicon.png";
import trade from "../img/trade.svg";
import earn from "../img/earn.svg";
import redeem from "../img/redeem.svg";
import { Link } from "react-router-dom";
import Reward from "../img/reward.jpg";

export default function KOZERewards() {
  return (
    <>
      <SideBar />
      <div className="container-fluid page-body-wrapper" id="Rewards">
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="reward-points">
              <div className="row g-3">
                <div className="col-12 col-lg-8">
                  <div
                    className="card h-100 d-flex justify-content-center px-3 px-md-5 py-3 text-white"
                    style={{
                      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${Reward})`,
                      objectFit: "contain",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                    }}
                  >
                    <h3>KOZE GLOBAL Rewards</h3>
                    <p>Reap more rewards as you trade</p>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="card h-100">
                    <div className="card-body p-3">
                      <p className="d-flex align-items-center gap-2 gap-md-3">
                        <img
                          src={logo}
                          alt=""
                          className="img-fluid"
                          style={{ maxWidth: "30px" }}
                        />
                        <span>K-Points</span>
                        <i className="bi bi-info-circle-fill"></i>
                      </p>
                      <h1>0</h1>
                      <p className="m-0">
                        Make your first deposit to unlock redemptions
                      </p>
                    </div>
                    <div className="card-footer p-3 d-flex justify-content-between align-items-center">
                      <Link to="#" className="text-decoration-none text-black">
                        Terms and Conditions
                      </Link>
                      <i className="bi bi-chevron-right"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card p-3 mt-3 reward-points">
              <h2 className="text-center">Discover Vantage Rewards</h2>
              <div className="row row-cols-1 row-cols-md-3 g-4">
                <div className="col">
                  <div className="text-center">
                    <img
                      src={trade}
                      alt=""
                      className="img-fluid mb-3"
                      style={{ maxWidth: "100px" }}
                    />
                    <h3>TRADE</h3>
                    <p>
                      Make every trade count by joining Vantage Rewards.
                      K-Points are earned every time you trade.
                    </p>
                  </div>
                </div>
                <div className="col">
                  <div className="text-center">
                    <img
                      src={earn}
                      alt=""
                      className="img-fluid mb-3"
                      style={{ maxWidth: "100px" }}
                    />
                    <h3>EARN</h3>
                    <p>
                      Earn additional K-Points by completing our daily, weekly,
                      monthly, and ad-hoc missions.
                    </p>
                  </div>
                </div>
                <div className="col">
                  <div className="text-center">
                    <img
                      src={redeem}
                      alt=""
                      className="img-fluid mb-3"
                      style={{ maxWidth: "100px" }}
                    />
                    <h3>REDEEM</h3>
                    <p>
                      Redeem your K-Points in exchange for any of our featured
                      rewards of your choice.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
