import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateLoginTimestamp } from "../../redux/loginSlice";

export default function DepositFormCrypto({ walletAddress, qrCode }) {
  const dispatch = useDispatch();

  useEffect(() => {
    const updateTimestamp = () => {
      const currentTime = new Date().getTime();
      localStorage.setItem("loginTimestamp", currentTime.toString());
      dispatch(updateLoginTimestamp());
    };

    updateTimestamp();
  }, [dispatch]);

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(walletAddress)
      .then(() => {
        alert("Wallet address copied to clipboard!");
      })
      .catch((error) => {
        console.error("Copy to clipboard error:", error);
        alert("Failed to copy wallet address.");
      });
  };

  return (
    <div>
      <div className="text-white  rounded">
        <h2 className="text-center fs-3 fs-md-2 text-black">Deposit Wallet</h2>
        <p className="text-danger small">
          NOTE: Your sent payment reflect in your account will take some time
          about 3 to 10 minutes. This QR code or wallet will use only one time
          if you want to make another transaction please generate new QR.
        </p>
        <p className="small text-black">
          Please transfer USDT only to this wallet address. Other assets will
          not be acceptable with our system.
        </p>
        <div className="text-center mb-3 mb-md-4">
          {qrCode ? (
            <img
              src={qrCode}
              alt="QR Code"
              className="img-fluid mb-2 mb-md-3"
              style={{ width: "80px", height: "80px" }}
            />
          ) : (
            <p className="small text-black">No QR code available</p>
          )}
          <input
            type="text"
            value={walletAddress}
            readOnly
            className="form-control-plaintext bg-secondary text-white text-center small"
            aria-label="Wallet address"
          />
        </div>
        <div className="text-center">
          <button
            onClick={copyToClipboard}
            className="btn text-black rounded border btn-sm"
            aria-label="Copy wallet address to clipboard"
          >
            Copy
          </button>
        </div>
      </div>
    </div>
  );
}
