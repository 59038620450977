import React from "react";
import SideBar from "./sideBar";
import logo from "../img/favicon.png";
import { Link } from "react-router-dom";
import Redeem from "../img/redemption.jpg";

export default function CashRedemption() {
  return (
    <>
      <SideBar />
      <div className="container-fluid page-body-wrapper" id="LuckyDraw">
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="reward-points">
              <div className="row g-3">
                <div className="col-12 col-md-8">
                  <div
                    className="card h-100 d-flex justify-content-center px-3 px-md-5 py-3 text-white"
                    style={{
                      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${Redeem})`,
                      objectFit: "contain",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                    }}
                  >
                    <h3 style={{ color: "white" }}>KOZE GLOBAL Rewards</h3>
                    <p style={{ color: "white" }}>
                      Reap more rewards as you trade
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="card h-100">
                    <div className="card-body p-3">
                      <p className="d-flex align-items-center gap-2 gap-md-3">
                        <img
                          src={logo}
                          alt=""
                          className="img-fluid"
                          style={{ maxWidth: "30px" }}
                        />
                        <span>K-Points</span>
                        <i className="bi bi-info-circle-fill"></i>
                      </p>
                      <h1 className="fs-2 fs-md-1">0</h1>
                      <p className="m-0 small">
                        Make your first deposit to unlock redemptions
                      </p>
                    </div>
                    <div className="card-footer p-3 d-flex justify-content-between align-items-center">
                      <Link
                        to="#"
                        className="text-decoration-none text-black small"
                      >
                        Terms and Conditions
                      </Link>
                      <i className="bi bi-chevron-right"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card p-3 p-md-5 mt-3">
              <div className="text-center">
                <h1 className="fs-2 fs-md-1">Cash Redemption</h1>
                <h4 className="fw-normal fs-5 fs-md-4">
                  Redeem instant cash by using your K-Points.
                </h4>
                <p className="fs-6 small">
                  Every 200 K-Points is worth USD$1. Cash Redemption is limited
                  to USD$50 per day.
                </p>
                <button className="btn text-black border rounded-3 py-2 px-4">
                  Redeem
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
