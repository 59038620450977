import React, { useState, useEffect } from "react";
import "../../assets/css/depositeFund.css";
import axios from "axios";
import { CiFaceSmile } from "react-icons/ci";
import { ImSad } from "react-icons/im";
import { updateLoginTimestamp } from "../../redux/loginSlice";
import { useDispatch } from "react-redux";

const BASE_URL = "https://crmapi.kozeglobal.com/api/";
const CRYPTO_URL = `${BASE_URL}withdrwa_fund_withdraw_crypto_api.php`;

export default function WithdrawFormCrypto({ chain, type }) {
  const [amount, setAmount] = useState("");
  const [address, setAddress] = useState("");
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [statusCode, setStatusCode] = useState(null);

  useEffect(() => {
    setAmount("");
    setAddress("");
    setOtp("");
    setError(null);
    setSuccess(null);
    setStatusCode(null);
  }, [chain, type]);

  const dispatch = useDispatch();

  useEffect(() => {
    const currentTime = new Date().getTime();
    localStorage.setItem("loginTimestamp", currentTime.toString());
    dispatch(updateLoginTimestamp());
  }, [dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);
    setStatusCode(null);

    const token = localStorage.getItem("userToken");
    if (!token) {
      setError("User token not found. Please log in.");
      setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append("walletaddress", address);
    formData.append("amount", amount);
    formData.append("otp", otp);
    formData.append("token", token);
    formData.append("chain", chain);

    try {
      const response = await axios.post(CRYPTO_URL, formData);
      console.log("API Response:", response);
      const result = response.data?.data?.result;
      const code = response.data?.data?.status;

      if (code === 200) {
        setSuccess(result);
        setStatusCode(code);
        setTimeout(() => {
          setSuccess(null);
          setAmount("");
          setAddress("");
          setOtp("");
        }, 5000);
      } else {
        setError(result || "An unexpected error occurred.");
        setStatusCode(code);
      }
    } catch (error) {
      console.error("API Error:", error);
      setError(
        error.response?.data?.message ||
          "An error occurred while submitting the form."
      );
      setStatusCode(error.response?.status || 200);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mt-4">
      <h4 className="mb-4">{type}</h4>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="amount" className="form-label">
            Amount
          </label>
          <input
            id="amount"
            className="form-control"
            type="number"
            placeholder="Enter deposit amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value.replace(/\D/g, ""))}
            required
            step="any"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="address" className="form-label">
            Address
          </label>
          <input
            id="address"
            className="form-control"
            type="text"
            placeholder="Enter wallet address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="otp" className="form-label">
            OTP
          </label>
          <input
            id="otp"
            className="form-control"
            type="text"
            placeholder="Enter your OTP"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            required
          />
        </div>
        <div className="text-center">
          <button
            type="submit"
            className="btn text-black border rounded "
            disabled={loading}
          >
            {loading ? "Submitting..." : "Submit"}
          </button>
        </div>
        {error && (
          <div
            className="alert mt-3"
            role="alert"
            style={{ backgroundColor: "#f8d7da", color: "#721c24" }}
          >
            {error}
            <ImSad />
          </div>
        )}
        {success && (
          <div
            className="alert mt-3"
            role="alert"
            style={{ backgroundColor: "#d4edda", color: "#155724" }}
          >
            {success} <CiFaceSmile />
          </div>
        )}
      </form>
    </div>
  );
}
