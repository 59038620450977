import { useState, useRef } from "react";
import AddImage from "../../img/addImage.svg";
import { toast } from "react-toastify";
import axios from "axios";
import { Link } from "react-router-dom";

const API_BASE_URL = "https://crmapi.kozeglobal.com/api/ekyc.php";

export default function UploadedDocs() {
  const [photosData, setPhotosData] = useState({
    frontpicture: "",
    backpicture: "",
  });

  const [selectedDocType, setSelectedDocType] = useState("aadhaar");

  const [filesToUpload, setFilesToUpload] = useState({
    frontpicture: null,
    backpicture: null,
  });

  const [uploadStatus, setUploadStatus] = useState({
    frontpicture: false,
    backpicture: false,
  });

  const [isFrontPictureUploaded, setIsFrontPictureUploaded] = useState(false);

  const frontPictureInputRef = useRef(null);
  const backPictureInputRef = useRef(null);

  const CardsArray = [
    {
      itemHeading: "Document Front",
      itemImageLink: photosData.frontpicture,
      itemName: "frontpicture",
      itemVerified: uploadStatus.frontpicture,
    },
    {
      itemHeading: "Document Back",
      itemImageLink: photosData.backpicture,
      itemName: "backpicture",
      itemVerified: uploadStatus.backpicture,
    },
  ];

  const handleChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];

    if (file && file.size > 2000000) {
      toast.error("File Size should not exceed 2MB !!");
      return;
    }

    if (file) {
      setPhotosData((prev) => ({
        ...prev,
        [name]: URL.createObjectURL(file),
      }));
      setFilesToUpload((prev) => ({
        ...prev,
        [name]: file,
      }));

      if (name === "frontpicture") {
        setIsFrontPictureUploaded(true);
      }
    }
  };

  const handleUpload = async () => {
    const token = localStorage.getItem("userToken");
    if (!token) {
      toast.error("Token not found. Please log in.");
      return;
    }

    const formData = new FormData();
    formData.append("doc_type", selectedDocType);
    formData.append("token", token);

    if (filesToUpload.frontpicture) {
      formData.append("doc_front", filesToUpload.frontpicture);
    }

    if (filesToUpload.backpicture) {
      formData.append("doc_back", filesToUpload.backpicture);
    }

    try {
      const response = await axios.post(API_BASE_URL, formData);
      console.log(response);
      if (response.data && response.data.data) {
        const { doc_front, doc_back } = response.data.data.response;

        setPhotosData({
          frontpicture: filesToUpload.frontpicture
            ? doc_front
            : photosData.frontpicture,
          backpicture: filesToUpload.backpicture
            ? doc_back
            : photosData.backpicture,
        });

        setUploadStatus({
          frontpicture: filesToUpload.frontpicture
            ? true
            : uploadStatus.frontpicture,
          backpicture: filesToUpload.backpicture
            ? true
            : uploadStatus.backpicture,
        });

        toast.success("Files Uploaded Successfully !!");

        setFilesToUpload({
          frontpicture: null,
          backpicture: null,
        });

        // Clear file inputs
        if (frontPictureInputRef.current) {
          frontPictureInputRef.current.value = "";
        }
        if (backPictureInputRef.current) {
          backPictureInputRef.current.value = "";
        }

        // Reset front picture uploaded status
        setIsFrontPictureUploaded(false);
      } else {
        toast.error("Response does not contain 'data'.");
      }
    } catch (error) {
      toast.error("Error uploading files.");
      console.error(
        "Upload Error:",
        error.response ? error.response.data : error.message
      );
    }
  };

  return (
    <div className="container">
      <div className="row mb-4">
        <div className="col-12">
          <div className="form-group">
            <label htmlFor="documentType" className="text-dark">
              Select Document Type
            </label>
            <select
              id="documentType"
              className="form-control fs-responsive"
              value={selectedDocType}
              onChange={(e) => setSelectedDocType(e.target.value)}
            >
              <option value="aadhaar">Aadhaar Card</option>
              <option value="pan">PAN Card</option>
              <option value="voter">Voter Card</option>
              <option value="passport">Passport</option>
            </select>
          </div>
        </div>
      </div>

      <div className="row">
        {CardsArray.map((element, key) => (
          <div className="col-12 col-md-6 mb-4" key={key}>
            <div
              className="card mb-4 document-upload"
              style={{ minHeight: "300px" }}
            >
              <div className="card-header d-flex justify-content-between align-items-center">
                <span
                  className="card-header-title fs-responsive"
                  title={element.itemHeading}
                >
                  {element.itemHeading}
                </span>
                <div className="d-flex align-items-center">
                  <button
                    className="btn btn-sm btn-outline-primary btn-rounded position-relative"
                    disabled={
                      element.itemName === "backpicture" &&
                      !isFrontPictureUploaded
                    }
                  >
                    <input
                      type="file"
                      className="file-container"
                      onChange={handleChange}
                      name={element.itemName}
                      accept=".jpg,.jpeg,.png"
                      disabled={
                        element.itemName === "backpicture" &&
                        !isFrontPictureUploaded
                      }
                      ref={
                        element.itemName === "frontpicture"
                          ? frontPictureInputRef
                          : backPictureInputRef
                      }
                    />
                    <i className="fal fa-edit text-black"></i>
                    <span>Update</span>
                  </button>
                </div>
              </div>
              <div className="card-body row no-gutters align-items-center p-2">
                <div
                  className={`w-100 section-doc-image ${
                    element.itemImageLink ? "d-block" : "d-none"
                  }`}
                >
                  <Link
                    className="cursor-pointer doc-link"
                    href={element.itemImageLink}
                    download={`document-${element.itemName}`}
                  >
                    <div className="document-preview d-flex align-items-center justify-content-center data-doc">
                      <img
                        src={element.itemImageLink}
                        alt="Preview"
                        className="img-fluid"
                      />
                    </div>
                  </Link>
                </div>
                <div
                  className={`w-100 section-doc-uploader ${
                    element.itemImageLink ? "d-none" : "d-block"
                  }`}
                >
                  <form
                    className="disabled-document cursor-pointer dropzone custom-dropzone needsclick"
                    method="post"
                    action=""
                  >
                    <div className="dz-message needsclick py-3 position-relative">
                      <input
                        type="file"
                        className="file-container"
                        onChange={handleChange}
                        name={element.itemName}
                        accept=".jpg,.jpeg,.png"
                        disabled={
                          element.itemName === "backpicture" &&
                          !isFrontPictureUploaded
                        }
                        ref={
                          element.itemName === "frontpicture"
                            ? frontPictureInputRef
                            : backPictureInputRef
                        }
                      />
                      <img
                        src={AddImage}
                        alt="Upload"
                        className="image-icon-upload"
                      />
                      <div className="mt-3 mb-2 text-secondary text-description">
                        <small className="d-block fs-sm">Drag Files</small>
                        <small className="d-block fs-sm">or</small>
                      </div>
                      <div className="container-choose-file">
                        <div className="btn btn-sm btn-outline-secondary btn-rounded text-black">
                          Choose a file
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="row">
        <div className="col-12">
          <button
            className="btn text-black border rounded btn-block fs-responsive"
            onClick={handleUpload}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
