import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import SideBar from "./sideBar";
import { Alert, Spinner, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { updateLoginTimestamp } from "../redux/loginSlice";

const BASE_API = "https://crmapi.kozeglobal.com/api/";
const TICKET_DETAILS = `${BASE_API}get_support_ticket_details.php`;
const TICKET_MESSAGE = `${BASE_API}support_ticket_user_exits_user_ans.php`;

const SupportSystem = () => {
  const { supportid } = useParams();
  const [ticket, setTicket] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    category: "",
    question: "",
    file: null,
  });
  const token = localStorage.getItem("userToken");

  const dispatch = useDispatch();

  useEffect(() => {
    const updateTimestamp = () => {
      const currentTime = new Date().getTime();
      localStorage.setItem("loginTimestamp", currentTime.toString());
      dispatch(updateLoginTimestamp());
    };

    updateTimestamp();
  }, [dispatch]);

  const fetchTicketDetails = async () => {
    setLoading(true);
    try {
      const response = await axios.post(TICKET_DETAILS, { token, supportid });
      if (response.data.data.status === 200) {
        setTicket(response.data.data.response);
      } else {
        toast.error("Failed to fetch ticket details.");
      }
    } catch (error) {
      console.error("Failed to fetch ticket details", error);
      setError("Failed to load ticket details.");
      toast.error("Failed to load ticket details.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTicketDetails();
  }, [supportid, token]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };

  const handleFileChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      file: e.target.files[0],
    }));
  };

  const handleImageUpload = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let imageBase64 = "";
    if (formData.file) {
      try {
        imageBase64 = await handleImageUpload(formData.file);
      } catch (error) {
        console.error("Error converting image to Base64:", error);
        toast.error("Failed to convert image.");
        return;
      }
    }

    const data = {
      image: imageBase64,
      question: formData.question,
      token,
      supportId: supportid,
    };

    try {
      const response = await axios.post(TICKET_MESSAGE, data);
      if (response.data.data.status === 200) {
        toast.success("Ticket submitted successfully!");
        setFormData({
          category: "",
          question: "",
          file: null,
        });
        document.getElementById("image-upload-1").value = null;

        fetchTicketDetails();
      } else {
        toast.error("Something went wrong.");
      }
    } catch (error) {
      console.error("Error submitting ticket:", error);
      toast.error("Failed to submit the ticket.");
    }
  };

  return (
    <>
      <SideBar />
      <div className="container-fluid page-body-wrapper">
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="row justify-content-center">
              <div className="col-md-10 mb-4">
                <div className="card">
                  <div className="card-body m-4">
                    <h5 className="card-title mb-4 text-black">
                      List of Support Tickets
                    </h5>

                    <div className="table-responsive">
                      {loading ? (
                        <div className="text-center">
                          <Spinner animation="border" />
                        </div>
                      ) : error ? (
                        <Alert variant="danger">{error}</Alert>
                      ) : (
                        <Table striped responsive className="custom-table">
                          <thead>
                            <tr>
                              <th></th>
                              <th>Date</th>
                              <th>Category</th>
                              <th>Details</th>
                              <th>Screenshot</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ticket.map((item, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.date}</td>
                                <td>{item.Category}</td>
                                <td>{item.Question}</td>
                                <td>
                                  <img
                                    src={item.s_image}
                                    alt="Screenshot"
                                    style={{ width: "100px", height: "100px" }}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-md-10 mb-4">
                <div className="card">
                  <div className="card-body m-4">
                    <h5 className="card-title mb-4 text-black">
                      Raise New Query
                    </h5>

                    <form onSubmit={handleSubmit}>
                      <div className="form-group mb-4">
                        <label
                          htmlFor="question-input-1"
                          className="form-label"
                        >
                          Enter Question
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="question-input-1"
                          name="question"
                          value={formData.question}
                          onChange={handleInputChange}
                          placeholder="Enter your question"
                        />
                      </div>

                      <div className="form-group mb-4">
                        <label htmlFor="image-upload-1" className="form-label">
                          Upload Image (if any)
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          id="image-upload-1"
                          onChange={handleFileChange}
                        />
                      </div>

                      <div className="text-center">
                        <button
                          type="submit"
                          className="text-black border rounded"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SupportSystem;
