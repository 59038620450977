import { useEffect, useState } from "react";
import axios from "axios";
import "../../assets/css/depositeFund.css";
import { toast } from "react-toastify";
import { updateLoginTimestamp } from "../../redux/loginSlice";
import { useDispatch } from "react-redux";

const BASE_URL = "https://crmapi.kozeglobal.com/api/";
const WITHDRAW = `${BASE_URL}withdraws_funds_add_wallet_bal_cash.php`;
const OTP_REQUEST_URL = `${BASE_URL}send_otp.php`;

export default function WithdrawFormCash({ balance }) {
  const [amount, setAmount] = useState("");
  const [otp, setOtp] = useState("");

  const [otpVerified, setOtpVerified] = useState(false);
  const [otpError, setOtpError] = useState("");
  const [otpInputDisabled, setOtpInputDisabled] = useState(true);

  const token = localStorage.getItem("userToken");
  const dispatch = useDispatch();

  useEffect(() => {
    const currentTime = new Date().getTime();
    localStorage.setItem("loginTimestamp", currentTime.toString());
    dispatch(updateLoginTimestamp());
  }, [dispatch]);

  const handleAmountChange = (e) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      setAmount(value);

      if (value && !otpVerified) {
        setOtpInputDisabled(false);
      }
    }
  };

  const handleOtpChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,6}$/.test(value)) {
      setOtp(value);
      setOtpError("");
    }
  };

  const data = {
    amount: amount,
    otp: otp,
    token: token,
    otp_type: "withdraw_cash_otp",
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!amount || !otp) {
      return;
    }

    try {
      const response = await axios.post(WITHDRAW, data);
      console.log(response);
      const status = response.data.data.status;

      if (status === 200) {
        setAmount("");
        setOtp("");
        setOtpVerified(true);
        setOtpError("");
        setOtpInputDisabled(true);
        toast.success("Withdrawal successful!");
      } else if (status === 400) {
        toast.error("An error occurred while processing your request.");
      }
    } catch (error) {
      console.error("Error:", error);

      toast.error("An error occurred while processing your request.");
    }
  };

  const requestOtp = async () => {
    if (!amount) {
      return;
    }

    try {
      const response = await axios.post(OTP_REQUEST_URL, data);
      console.log(response);
      const status = response.data.data.status;
      if (status) {
        setOtpVerified(false);
        setOtpError("");
        setOtpInputDisabled(false);
        toast.info("OTP sent successfully!");
      } else {
        setOtpError(response.data.message || "Failed to send OTP");
        toast.error(response.data.message || "Failed to send OTP");
      }
    } catch (error) {
      console.error("Error:", error);
      setOtpError("An error occurred while sending OTP.");
      toast.error("An error occurred while sending OTP.");
    }
  };

  return (
    <div className="card-body mt-4">
      <h4>Withdraw Wallet Balance (Cash)</h4>
      <b>Current Wallet Fund: {balance} $</b>
      <div className="row">
        <div className="col-md-6">
          <form
            id="withdrawcashform"
            name="withdrawcashform"
            onSubmit={handleSubmit}
          >
            <div className="form-group mt-3">
              <label>Enter Amount:</label>
              <input
                type="text"
                id="add_fund_amount_usdt"
                name="amount"
                className="form-control"
                value={amount}
                onChange={handleAmountChange}
                placeholder="Enter Amount"
                required
              />
            </div>
            <div className="form-group">
              <label>Enter OTP:</label>
              <input
                type="text"
                name="otp"
                id="otp"
                className="form-control"
                value={otp}
                onChange={handleOtpChange}
                maxLength={6}
                pattern="\d*"
                title="Enter a 6-digit OTP"
                disabled={otpInputDisabled}
              />
              {otpError && <p className="text-danger">{otpError}</p>}
            </div>
            <input
              type="hidden"
              name="type"
              id="type"
              value="addwithdrawcash"
            />
            <div className="form-group mt-3">
              <button
                type="button"
                id="requestOtp"
                className="btn text-black border rounded waves-effect waves-light m-2"
                onClick={requestOtp}
                disabled={otpVerified}
              >
                Send OTP
              </button>
              <button
                type="submit"
                id="submit"
                className="btn text-black border rounded waves-effect waves-light m-2"
              >
                {otpVerified ? "OTP Verified" : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
