import React from "react";

export default function VIPSupport() {
  return (
    <>
      <div className="mx-auto text-center mt-5 px-3">
        <div className="row align-items-center">
          <div className="col-12 col-md-6 text-start">
            <h3 className="mt-3">On Speed Dial</h3>
            <p className="">
              Have a question you'd like to ask an analyst directly? Simply
              slide into their DMs with our full-fledge direct messaging system.
            </p>
          </div>
          <div className="col-12 col-md-6 text-start">
            <h3 className="mt-3">
              Our analysts are only a direct message away
            </h3>
            <p className="">
              Nowhere else can you find an award-winning team of friendly
              traders you can easily reach out to!
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 text-start">
            <h3 className="mt-3">
              Receive help from our VIP Support instantly
            </h3>
            <p className="">
              At Koze Global Markets, you get the concierge treatment both in
              terms of trading support and client support. Our dedicated VIP
              support team is just a DM away.
            </p>
          </div>
        </div>

        <div class="w-100 text-white">
          <div class="py-2 px-2  rounded-4" style={{ background: "#1c1c1c" }}>
            <div className="mb-5 ">
              <h2 class="fw-bolder">Join Koze Global VIP Today!</h2>
            </div>
            <div className="small">
              <p>
                Take your trading to the next level by getting daily exclusive
                market insights and guidance from our pool of award-winning
                traders. Simply deposit the relevant amount into your Koze
                Global Markets trading account and the different access levels
                will be unlocked for you.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
