import React from "react";

export default function RequestResearch() {
  return (
    <>
      <div className="mx-auto text-center mt-5 px-3">
        <div className="row align-items-center">
          <div className="col-12 col-md-6 text-start">
            <h3 className="mt-3">Request-A-Research</h3>
            <p className="">
              In the Koze Global Markets VIP Room, you can request for insights
              and research on any instrument. Our team of award winning analysts
              will attend to your requests.
            </p>
          </div>
          <div className="col-12 col-md-6 text-start">
            <h3 className="mt-3">Request a research on any instrument</h3>
            <p className="">
              In the Koze Global Markets VIP Room, we cover a wide range of
              markets ranging from Forex, Indices, Commodities, Cryptocurrencies
              and even popular shares.
            </p>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-12 col-md-6 text-start">
            <h3 className="mt-3">
              Our pool of analysts will look into it for you
            </h3>
            <p className="">
              Have a position in $BTCUSD and would like to know where it might
              head to? Just @mention one of our analysts and he'll look into it
              for you.
            </p>
          </div>

          <div className="col-12 col-md-6 text-start">
            <h3 className="mt-3">Get expert analysis on demand</h3>
            <p className="">
              Our team of experienced analysts is ready to provide in-depth
              research and insights on any financial instrument you're
              interested in. Just submit a request and we'll deliver detailed
              analysis to help inform your trading decisions.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
