import axios from "axios";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { updateLoginTimestamp } from "../../redux/loginSlice";

const API = "https://crmapi.kozeglobal.com/api";
const FILTER = `${API}/get_all_ib_withdraw_history_filter.php`;

const IBHistory = ({ data = [] }) => {
  const token = localStorage.getItem("userToken");
  const [fdate, setFdate] = useState("");
  const [edate, setEdate] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [transactionType, setTransactionType] = useState("All");
  const [transactionStatus, setTransactionStatus] = useState("All");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [validationError, setValidationError] = useState(null);
  const [isFiltered, setIsFiltered] = useState(false);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const dispatch = useDispatch();

  useEffect(() => {
    const updateTimestamp = () => {
      const currentTime = new Date().getTime();
      localStorage.setItem("loginTimestamp", currentTime.toString());
      dispatch(updateLoginTimestamp());
    };

    updateTimestamp();
  }, [dispatch]);

  const today = new Date().toISOString().split("T")[0];

  const isSubmitButtonDisabled = !fdate || !edate;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isSubmitButtonDisabled) return;

    if (new Date(fdate) > new Date(edate)) {
      setValidationError("The 'From' date cannot be later than the 'To' date.");
      return;
    }

    setLoading(true);
    setError(null);
    setValidationError(null);

    const requestData = {
      token,
      fdate,
      edate,
      type: transactionType,
      status: transactionStatus,
    };

    try {
      const response = await axios.post(FILTER, requestData);
      console.log("API Response:", response);
      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.response
      ) {
        setFilteredData(response.data.data.response);
      } else {
        setFilteredData([]);
        setError(
          "Unexpected response structure or no data returned from the server."
        );
      }

      setIsFiltered(true);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(
        "An error occurred while fetching the data. Please try again later."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleReset = () => {
    setFilteredData(Array.isArray(data) ? data : []);
    setIsFiltered(false);
    setFdate("");
    setEdate("");
    setValidationError(null);
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };

  const formatAmount = (amount) => {
    return amount ? parseFloat(amount).toFixed(2) : "-";
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="d-flex gap-1 justify-content-center align-items-center flex-wrap flex-md-nowrap">
                <div className="form-group">
                  <label htmlFor="from">From</label>
                  <input
                    type="date"
                    className="form-control"
                    id="from"
                    value={fdate}
                    onChange={(e) => setFdate(e.target.value)}
                    max={today}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="to">To</label>
                  <input
                    type="date"
                    className="form-control"
                    id="to"
                    value={edate}
                    onChange={(e) => setEdate(e.target.value)}
                    max={today}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="transaction-type-select">Type</label>
                  <select
                    className="form-control"
                    id="transaction-type-select"
                    value={transactionType}
                    onChange={(e) => setTransactionType(e.target.value)}
                  >
                    <option>All</option>
                    <option>Bank</option>
                    <option>Cash</option>
                    <option>Crypto</option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="transaction-status-select">Status</label>
                  <select
                    className="form-control"
                    id="transaction-status-select"
                    value={transactionStatus}
                    onChange={(e) => setTransactionStatus(e.target.value)}
                  >
                    <option>All</option>
                    <option>Approved</option>
                    <option>Pending</option>
                    <option>Rejected</option>
                  </select>
                </div>

                <button
                  className="btn text-black border rounded mt-2"
                  onClick={handleSubmit}
                  disabled={isSubmitButtonDisabled}
                >
                  Submit
                </button>
                {isFiltered && (
                  <button
                    className="btn text-black border rounded mt-2 ms-2"
                    onClick={handleReset}
                  >
                    Reset
                  </button>
                )}
              </div>
              {loading && <p>Loading...</p>}
              {error && <p className="text-danger">{error}</p>}
              {validationError && (
                <p className="text-danger">{validationError}</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="card-body mt-4">
        <p className="mb-4">
          <strong>Something:</strong> 84.47
        </p>
        {filteredData.length > 0 ? (
          <Table striped responsive className="custom-table">
            <thead>
              <tr>
                <th></th>
                <th>Date</th>
                <th>Amount</th>
                <th>Method</th>
                <th>Details</th>
                <th>Status</th>
                <th>Remark</th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{formatDate(item.date)}</td>
                  <td>{formatAmount(item.amount)}</td>
                  <td>{item.withdraw_type || "-"}</td>
                  <td>{item.withdraw_type_details || "-"}</td>
                  <td>{item.status || "-"}</td>
                  <td>{item.remark || "-"}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <Table striped responsive className="custom-table">
            <tbody>
              <tr style={{ height: "100px" }}>
                <td colSpan="8" className="text-center">
                  No data available
                </td>
              </tr>
            </tbody>
          </Table>
        )}
      </div>
    </>
  );
};

export default IBHistory;
