import React, { useState, useEffect, useRef } from "react";
import SideBar from "./sideBar";
import "../assets/css/TransactionHistory.css";
import DepositHistory from "./TransactionsHistory/depositHistory";
import WithdrawHistory from "./TransactionsHistory/withdrawHistory";
import TransferHistory from "./TransactionsHistory/transferHistory";
import WalletHistory from "./TransactionsHistory/walletHistory";
import DealReport from "./TransactionsHistory/dealReport";
import axios from "axios";
import { useDispatch } from "react-redux";
import { updateLoginTimestamp } from "../redux/loginSlice";

const BASE_API = "https://crmapi.kozeglobal.com/api/";
const ALL_DEPOSIT = `${BASE_API}get_all_deposit_history.php`;
const ALL_WITHDRAWAL = `${BASE_API}get_all_withdraw_history.php`;
const IB_HISTORY = `${BASE_API}get_all_ib_withdraw_history.php`;
const WALLET_HISTORY = `${BASE_API}get_all_wallet_history.php`;

export default function TransactionHistory() {
  const [defaultAccount, setDefaultAccount] = useState(0);
  const [depositData, setDepositData] = useState(null);
  const [withdrawData, setWithdrawData] = useState(null);
  const [ibHistoryData, setIbHistoryData] = useState(null);
  const [walletData, setWalletData] = useState(null);
  const [loading, setLoading] = useState(false);

  const token = localStorage.getItem("userToken");
  const dispatch = useDispatch();

  const AccountButton = [
    { text: "Deposits" },
    { text: "Withdrawals" },
    { text: "IB Withdrawal" },
    { text: "Wallet History" },
    { text: "Deal Report" },
  ];

  const scrollContainerRef = useRef(null);
  const tabRefs = useRef([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        switch (defaultAccount) {
          case 0:
            const allDeposit = await axios.post(ALL_DEPOSIT, { token });
            setDepositData(allDeposit.data.data.response);
            break;

          case 1:
            const allWithdrawal = await axios.post(ALL_WITHDRAWAL, { token });
            setWithdrawData(allWithdrawal.data.data.response);
            break;

          case 2:
            const IbHistory = await axios.post(IB_HISTORY, { token });
            setIbHistoryData(IbHistory.data.data.response);
            break;

          case 3:
            const walletHistory = await axios.post(WALLET_HISTORY, { token });
            setWalletData(walletHistory.data.data.response);
            break;

          default:
            console.warn("Unknown account type:", defaultAccount);
            break;
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [defaultAccount, token]);

  useEffect(() => {
    const updateTimestamp = () => {
      const currentTime = new Date().getTime();
      localStorage.setItem("loginTimestamp", currentTime.toString());
      dispatch(updateLoginTimestamp());
    };

    updateTimestamp();
  }, [dispatch]);

  const handleAccountType = (index) => {
    setDefaultAccount(index);
    if (scrollContainerRef.current) {
      const container = scrollContainerRef.current;
      const button = container.children[index];
      const containerWidth = container.offsetWidth;
      const buttonWidth = button.offsetWidth;
      const scrollLeft =
        button.offsetLeft - containerWidth / 2 + buttonWidth / 2;

      container.scrollTo({
        left: scrollLeft,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <SideBar />
      <div
        className="container-fluid page-body-wrapper d-flex flex-column align-items-center"
        style={{ height: "100%", backgroundColor: "#1C1C1C" }}
        id="TransactionHistory"
      >
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="row justify-content-center">
              <div className="col-12 col-md-10 col-lg-8">
                <div className="pt-2">
                  <div
                    className="d-flex tab-container"
                    ref={scrollContainerRef}
                    style={{ overflowX: "auto" }}
                  >
                    {AccountButton.map((element, key) => (
                      <div
                        className="flex-shrink-0 tab-item"
                        key={key}
                        ref={(el) => (tabRefs.current[key] = el)}
                      >
                        <button
                          className={`${
                            key === defaultAccount ? "is-active-tab" : "btn"
                          } btn text-black fs-5 fs-md-4 fs-lg-2 bg-white`}
                          onClick={() => handleAccountType(key)}
                        >
                          {element.text}
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="card">
                  {loading ? (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: "200px" }}
                    >
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <>
                      {defaultAccount === 0 && (
                        <DepositHistory data={depositData} />
                      )}
                      {defaultAccount === 1 && (
                        <WithdrawHistory data={withdrawData} />
                      )}
                      {defaultAccount === 2 && (
                        <TransferHistory data={ibHistoryData} />
                      )}
                      {defaultAccount === 3 && (
                        <WalletHistory data={walletData} />
                      )}
                      {defaultAccount === 4 && <DealReport />}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
