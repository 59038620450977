import { useState, useEffect } from "react";
import {
  Button,
  Card,
  Container,
  Form,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const API_BASE_URL = "https://crmapi.kozeglobal.com/api";
const ENDPOINTS = {
  EMAIL_CONN: `${API_BASE_URL}/connect_auth_otp_send.php`,
  EMAIL_VERIFY: `${API_BASE_URL}/connect_auth_otp_send_verify.php`,
  DIS_OTP: `${API_BASE_URL}/unlink_auth_otpy.php`,
  DIS_VERIFY: `${API_BASE_URL}/unlink_auth_verify.php`,
  QR_CREATE: `${API_BASE_URL}/qr_create.php`,
  QR_VERIFY: `${API_BASE_URL}/qr_verify.php`,
};

const Auth = () => {
  const [otp, setOtp] = useState("");
  const [showSubmit, setShowSubmit] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [qr, setQr] = useState(null);
  const [secret, setSecret] = useState("");
  const [gmailOtp, setGmailOtp] = useState("");
  const [authType, setAuthType] = useState(null);
  const [showEmailVerification, setShowEmailVerification] = useState(false);
  const [showGmailVerification, setShowGmailVerification] = useState(false);
  const [loading, setLoading] = useState(false);
  const userInfo = useSelector((state) => state.user);
  console.log(userInfo.userInfo);
  const token = localStorage.getItem("userToken");

  useEffect(() => {
    if (userInfo.userInfo?.authType) setAuthType(userInfo.userInfo.authType);
  }, [userInfo.userInfo?.authType]);

  const handleOTPChange = (e) => setOtp(e.target.value);

  const handleSendOTP = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(ENDPOINTS.EMAIL_CONN, { token });
      console.log(response);
      if (response.data.data.result) {
        toast.success("OTP sent to your email.");
        setShowSubmit(true);
        setOtpSent(true);
      } else {
        toast.error("Failed to send OTP.");
      }
    } catch (error) {
      toast.error("Error sending OTP: " + error.message);
    }
    setLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(ENDPOINTS.EMAIL_VERIFY, { token, otp });
      console.log(response);
      if (response.data.data.status === 200) {
        toast.success("OTP verified successfully.");
      } else {
        toast.error("Invalid OTP.");
      }
    } catch (error) {
      toast.error("Error verifying OTP: " + error.message);
    }
    setLoading(false);
  };

  const handleDisEmail = async () => {
    setShowEmailVerification(true);
    setLoading(true);
    try {
      const response = await axios.post(ENDPOINTS.DIS_OTP, { token });
      console.log(response);
      if (response.data.data.result) {
        toast.success("OTP sent for disconnection.");
      } else {
        toast.error("Failed to send disconnection OTP.");
      }
    } catch (error) {
      toast.error("Error disconnecting email: " + error.message);
    }
    setLoading(false);
  };

  const handleDisVerify = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(ENDPOINTS.DIS_VERIFY, { otp, token });
      if (response.data.data.status === 200) {
        toast.success("Your account is unlinked.");
      } else {
        toast.error("Invalid OTP.");
      }
    } catch (error) {
      toast.error("Error verifying disconnection OTP: " + error.message);
    }
    setLoading(false);
  };

  const handleInput = () => {
    setShowEmailVerification(true);
  };

  const handleGmailOTP = (e) => setGmailOtp(e.target.value);

  useEffect(() => {
    const fetchQRCode = async () => {
      try {
        const response = await axios.post(ENDPOINTS.QR_CREATE, { token });
        console.log(token);
        console.log(response);
        setQr(response.data.data.response.qr);
        setSecret(response.data.data.response.secret);
      } catch (error) {
        toast.error("Unable to get QR code: " + error.message);
      }
    };
    fetchQRCode();
  }, [token]);

  const handleQRVerify = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(ENDPOINTS.QR_VERIFY, {
        otp: gmailOtp,
        token,
      });
      if (response.data.data.result) {
        toast.success("QR code verified successfully.");
      } else {
        toast.error("Invalid OTP.");
      }
    } catch (error) {
      toast.error("Error verifying QR code: " + error.message);
    }
    setLoading(false);
  };

  const handleInputGmail = () => {
    setShowGmailVerification(true);
  };

  const handleDisVerifyGmail = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(ENDPOINTS.DIS_VERIFY, {
        otp: gmailOtp,
        token,
      });
      console.log(response);
      if (response.data.data.status === 200) {
        toast.success("Your account is unlinked.");
      } else {
        toast.error("Invalid OTP.");
      }
    } catch (error) {
      toast.error("Error verifying disconnection OTP: " + error.message);
    }
    setLoading(false);
  };

  const handleClick = () => {
    toast.error("Please disconnect your last auth first.");
  };

  return (
    <div className="d-flex flex-column">
      {loading && (
        <div
          className="d-flex justify-content-center align-items-center w-100"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            height: "100%",
            zIndex: 1050,
            background: "rgba(255,255,255,0.7)",
          }}
        >
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      <Container className="">
        <Row className="justify-content-center">
          {/* Email Auth */}
          <Col md={6} className="mb-4">
            <Card className="shadow-sm border-0 rounded h-auto">
              <Card.Body>
                <div className="card-header">
                  <h4 className="card-title">Email Authentication</h4>
                </div>
                <Form onSubmit={handleSubmit}>
                  {authType == 0 && (
                    <div className="justify-content-center align-items-center m-4">
                      <Form.Group as={Row} className="align-items-center">
                        <Col md={8}>
                          <Form.Control
                            type="text"
                            placeholder="Enter OTP"
                            value={otp}
                            onChange={handleOTPChange}
                            disabled={!otpSent}
                          />
                        </Col>
                        <Col md={4}>
                          <Link
                            className="w-30 d-block text-center"
                            style={{
                              cursor: "pointer",
                              textDecoration: "none",
                              color: "#07548D",
                            }}
                            onClick={handleSendOTP}
                          >
                            Get OTP
                          </Link>
                        </Col>
                      </Form.Group>
                      {showSubmit && (
                        <Form.Group
                          as={Row}
                          className="align-items-center mt-3"
                        >
                          <Col
                            md={12}
                            className="d-flex justify-content-center"
                          >
                            <button className="border rounded">Submit</button>
                          </Col>
                        </Form.Group>
                      )}
                    </div>
                  )}
                  {authType == 1 && (
                    <div className="d-flex justify-content-center my-4">
                      <button>Connect</button>
                    </div>
                  )}
                </Form>

                {authType == 2 && (
                  <>
                    <div className="d-flex justify-content-center my-4">
                      <div onClick={handleInput}>
                        {!showEmailVerification && (
                          <Button variant="danger">Disconnect</Button>
                        )}
                      </div>
                    </div>
                    {showEmailVerification && (
                      <Form onSubmit={handleDisVerify}>
                        <Form.Group as={Row} className="align-items-center">
                          <Col md={8}>
                            <Form.Control
                              type="text"
                              className="m-2"
                              placeholder="Enter OTP"
                              value={otp}
                              onChange={handleOTPChange}
                            />
                          </Col>
                          <Col md={4}>
                            <Link
                              className="w-100 d-block text-center"
                              style={{
                                cursor: "pointer",
                                textDecoration: "none",
                                color: "orange",
                              }}
                              onClick={handleDisEmail}
                            >
                              Get OTP
                            </Link>
                          </Col>
                        </Form.Group>
                        <Form.Group
                          as={Row}
                          className="align-items-center mt-3"
                        >
                          <Col
                            md={12}
                            className="d-flex justify-content-center"
                          >
                            <button className="w-30 m-4" type="submit">
                              Verify OTP
                            </button>
                          </Col>
                        </Form.Group>
                      </Form>
                    )}
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
          {/* Google Auth */}

          <Col md={6} className="mb-4">
            <Card className="shadow-sm border-0 rounded h-100">
              <Card.Body>
                <div className="card-header">
                  <h4 className="card-title">Google</h4>
                </div>
                {authType != 2 && (
                  <Form onSubmit={handleQRVerify}>
                    {authType == 0 && (
                      <>
                        <div className="d-flex justify-content-center mb-4">
                          {qr && (
                            <img
                              src={qr}
                              alt="QR Code"
                              className="mx-auto d-block"
                              style={{
                                margin: "2rem",
                                width: "150px",
                                height: "150px",
                              }}
                            />
                          )}
                        </div>
                        <Form.Group>
                          <Form.Control
                            style={{ width: "70%", margin: "0 auto" }}
                            type="text"
                            value={secret}
                            readOnly
                            className="mb-2"
                            placeholder="Secret Key"
                          />
                          <Form.Control
                            style={{ width: "70%", margin: "0 auto" }}
                            type="text"
                            value={gmailOtp}
                            onChange={handleGmailOTP}
                            required
                            className="mb-2"
                            placeholder="Enter OTP"
                          />
                        </Form.Group>
                        <div className="d-flex justify-content-center">
                          <button className="border rounded mb-4">
                            Submit
                          </button>
                        </div>
                      </>
                    )}
                  </Form>
                )}
                <div className="d-flex justify-content-center">
                  {authType == 2 ? (
                    <div>
                      <div
                        className="btn d-flex justify-content-center my-4"
                        onClick={handleClick}
                      >
                        Connect
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
                {authType == 1 && (
                  <>
                    <div className="d-flex justify-content-center my-4">
                      <div onClick={handleInputGmail}>
                        {!showGmailVerification && (
                          <Button variant="danger">Disconnect</Button>
                        )}
                      </div>
                    </div>
                    {showGmailVerification && (
                      <div className="d-flex justify-content-center align-items-center m-2">
                        <Form onSubmit={handleDisVerifyGmail} className="w-50">
                          <Form.Group as={Row} className="align-items-center">
                            <Col md={12}>
                              <Form.Control
                                type="text"
                                placeholder="Enter OTP"
                                value={gmailOtp}
                                onChange={handleGmailOTP}
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group
                            as={Row}
                            className="align-items-center mt-3"
                          >
                            <Col
                              md={12}
                              className="d-flex justify-content-center"
                            >
                              <button className="w-50" type="submit">
                                Verify OTP
                              </button>
                            </Col>
                          </Form.Group>
                        </Form>
                      </div>
                    )}
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Auth;
