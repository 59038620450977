import React from "react";
import { Link, Navigate } from "react-router-dom";
import logo from "../img/favicon.png";
import "jquery-ui-dist/jquery-ui";

export default function Header() {
  const handleLogOut = () => {
    localStorage.clear();
    return <Navigate to="/login" replace />;
  };

  return (
    <>
      <div>
        <nav className="navbar p-0 fixed-top d-flex flex-row">
          <div
            className="navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center"
            style={{ width: "100px" }}
          >
            <Link className="navbar-brand brand-logo-mini" href="index.html">
              <img src={logo} alt="logo" />
            </Link>
          </div>
          <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
            <button
              className="navbar-toggler navbar-toggler align-self-center"
              type="button"
              data-toggle="minimize"
            >
              <i className="bi bi-list fs-4 m-0"></i>
            </button>
            <ul className="navbar-nav w-100">
              {/* <li className="nav-item w-100">
              <form className="nav-link mt-2 mt-md-0 d-none d-lg-flex search">
                <input type="text" className="form-control" placeholder="Search products" />
              </form>
            </li> */}
            </ul>
            <ul className="navbar-nav navbar-nav-right">
              <li className="nav-item dropdown d-none d-lg-block">
                <Link
                  className="nav-link btn btn-success create-new-button"
                  id="DepositeButton"
                  to="/DepositeFunds"
                >
                  Deposit
                </Link>
              </li>
              <li className="nav-item dropdown d-none d-lg-block">
                <button className="btn d-flex text-black">
                  <i className="bi bi-globe2"></i>
                  English
                </button>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className="nav-link"
                  id="profileDropdown"
                  href="#"
                  data-bs-toggle="dropdown"
                >
                  <div className="navbar-profile">
                    <img
                      className="img-xs rounded-circle"
                      src="https://icons.veryicon.com/png/o/internet--web/prejudice/user-128.png"
                      alt=""
                    />
                  </div>
                </Link>
                <div
                  className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list"
                  aria-labelledby="profileDropdown"
                >
                  <h6 className="p-3 mb-0">Profile</h6>
                  <div className="dropdown-divider"></div>
                  <Link className="dropdown-item preview-item">
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-dark rounded-circle">
                        <i className="bi bi-gear-fill text-success"></i>
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <p className="preview-subject mb-1">Settings</p>
                    </div>
                  </Link>
                  <div className="dropdown-divider"></div>
                  <Link className="dropdown-item preview-item" to="/login">
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-dark rounded-circle">
                        <i className="bi bi-box-arrow-right text-danger"></i>
                      </div>
                    </div>
                    <div
                      className="preview-item-content"
                      onClick={handleLogOut}
                    >
                      <p className="preview-subject mb-1">Log out</p>
                    </div>
                  </Link>
                  <div className="dropdown-divider"></div>
                  <p className="p-3 mb-0 text-center">Advanced settings</p>
                </div>
              </li>
            </ul>
            <button
              className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
              type="button"
              data-toggle="offcanvas"
            >
              <span className="bi bi-list"></span>
            </button>
          </div>
        </nav>
      </div>
    </>
  );
}
