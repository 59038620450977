import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Register from "./pages/register";
import LogIn from "./pages/login";
import Home from "./pages/Home";
import DepositFunds from "./pages/DepositFunds";
import MyProfile from "./pages/MyProfile";
import WithdrawFunds from "./pages/WithdrawFunds";
import WithdrawFromBank from "./pages/Withdraw/WithdrawFromBank";
import WithdrawFormCash from "./pages/Withdraw/WithdrawFormCash";
import WithdrawFormCrypto from "./pages/Withdraw/WithdrawFormCrypto";
import TransferBetweenAccount from "./pages/TransferBetweenAccount";
import TransactionHistory from "./pages/TransactionHistory";
import DownloadApp from "./pages/DownloadApp";
import ProTraderTools from "./pages/ProTraderTools";
import TechnicalAnalys from "./pages/TechnicalAnalysis";
import NationalVolumeCalculator from "./pages/NationalVolumeCalculator";
import VIPTradingRoom from "./pages/VIPTradingRoom";
import KozeRewards from "./pages/Rewards";
import WheelSpin from "./pages/WheelSpin";
import LuckyDraw from "./pages/LuckyDraw";
import CashRedemption from "./pages/CashRedemption";
import VouchersRedemption from "./pages/VouchersRedemption";
import PrivateRoutes from "./privateroutes/PrivateRoutes";
import { toast } from "react-toastify";
import ForgotPassword from "./pages/forgotPassword";
import Support from "./pages/support";
import SupportSystem from "./pages/SupportSystem";
import ResetPassword from "./pages/resetPassword";
import MT5Account from "./pages/MT5WALLET";
import WALLETMT5 from "./pages/WALLETMT5";

export default function App() {
  const handleSessionCheck = () => {
    const diff = Date.now() - localStorage.getItem("sessionTime");
    if (diff / 1000 > 10800) {
      toast.info("Session Expired Login Again !!");
      localStorage.clear();

      setTimeout(() => {
        window.location.href = "/login";
      }, 2000);
    } else {
      localStorage.setItem("sessionTime", Date.now());
    }
  };

  useEffect(() => {
    if (localStorage.getItem("sessionTime")) {
      handleSessionCheck();
    }
    // eslint-disable-next-line
  }, [window.location.pathname]);

  window.addEventListener("click", () => {
    if (localStorage.getItem("sessionTime")) {
      handleSessionCheck();
    }
  });

  return (
    <>
      <>
        <BrowserRouter>
          <Routes>
            <Route path="/register" element={<Register />} />

            <Route path="/login" element={<LogIn />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />

            <Route path="/reset-password" element={<ResetPassword />} />

            <Route element={<PrivateRoutes />}>
              <Route index path="/" element={<Home />} />
              <Route path="/home" element={<Home />} />

              <Route path="/Profile" element={<MyProfile />} />

              <Route path="/DepositeFunds" element={<DepositFunds />} />

              <Route path="/WithdrawFunds" element={<WithdrawFunds />} />

              <Route
                path="/WithdrawFunds/PaymentWithdraw"
                element={<WithdrawFromBank />}
              />
              <Route
                path="/WithdrawFunds/PaymentWithdrawCash"
                element={<WithdrawFormCash />}
              />
              <Route
                path="/WithdrawFunds/PaymentWithdrawCrypto"
                element={<WithdrawFormCrypto />}
              />

              <Route
                path="/TransferBetweenAccounts"
                element={<TransferBetweenAccount />}
              />
              <Route path="/mt5account" element={<MT5Account />} />
              <Route path="/walletmt5" element={<WALLETMT5 />} />
              <Route
                path="/TransactionHistory"
                element={<TransactionHistory />}
              />

              <Route path="/DownloadApp" element={<DownloadApp />} />

              <Route path="/support" element={<Support />} />
              <Route path="/support/:supportid" element={<SupportSystem />} />
              <Route path="/ProTraderTool" element={<ProTraderTools />} />
              <Route path="/TechnicalAnalysis" element={<TechnicalAnalys />} />
              <Route
                path="/NationalVolumeCalculator"
                element={<NationalVolumeCalculator />}
              />
              <Route path="/VIPTradingRoom" element={<VIPTradingRoom />} />

              <Route path="/RewardsOverview" element={<KozeRewards />} />
              <Route path="/WheelSpin" element={<WheelSpin />} />
              <Route path="/LuckyDraw" element={<LuckyDraw />} />
              <Route path="/CashRedemption" element={<CashRedemption />} />
              <Route
                path="/VouchersRedemption"
                element={<VouchersRedemption />}
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </>
    </>
  );
}
