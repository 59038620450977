import React, { useEffect } from "react";
import SideBar from "./sideBar";
import "../assets/css/NationalVolumeCalculator.css";
import { useDispatch } from "react-redux";
import { updateLoginTimestamp } from "../redux/loginSlice";

export default function NationalVolumeCalculator() {
  const dispatch = useDispatch();

  useEffect(() => {
    const updateTimestamp = () => {
      const currentTime = new Date().getTime();
      localStorage.setItem("loginTimestamp", currentTime.toString());
      dispatch(updateLoginTimestamp());
    };

    updateTimestamp();
  }, [dispatch]);
  return (
    <>
      <SideBar />
      <div
        className="container-fluid page-body-wrapper"
        id="NationalVolumeCalculator"
      >
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="row justify-content-center">
              {/* <div className="page-header mb-4" style={{ marginLeft: "14rem" }}>
                <h3 className="page-title">National Volume Calculator</h3>
              </div> */}
              <div className="col-md-10 grid-margin stretch-card d-flex justify-content-center">
                <div className="text-black p-4">
                  <div
                    className="VolumeCalculator rounded "
                    style={{ width: "100%" }}
                  >
                    <form action="">
                      <div className="form-group mt-3">
                        <label>Product</label>
                        <select name="" id="" className="form-control">
                          <option value="">Select</option>
                          <option value="">CHINA50</option>
                          <option value="">Nikkiei225</option>
                          <option value="">BTHUSD</option>
                          <option value="">ETHUSD</option>
                        </select>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <div className="form-group">
                            <label>Trading Lots</label>
                            <input
                              type="number"
                              placeholder="0"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="form-group">
                            <label>Choose Price</label>
                            <input
                              type="number"
                              placeholder="0"
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="card py-4 px-3">
                        <div className="row">
                          <div className="col-6 d-flex flex-column">
                            <label>Contract Size</label>
                            <span>-</span>
                          </div>
                          <div className="col-6 d-flex flex-column">
                            <label>Exchange Rate</label>
                            <span>-</span>
                          </div>
                        </div>
                      </div>
                      <div className="row pt-3">
                        <div className="col-12 col-md-6">
                          <button className="btn text-black rounded border text-black w-100 rounded-3 py-2">
                            Reset
                          </button>
                        </div>
                        <div className="col-12 col-md-6">
                          <button className="btn text-black rounded border w-100 text-black rounded-3 py-2">
                            Calculator
                          </button>
                        </div>
                      </div>
                    </form>
                    <div className="mt-3 p-3 bg-gradient-dark">
                      <p className="m-0">
                        <i className="bi bi-wallet"></i>Approx. Notional Volume
                        (USD) <i className="bi bi-info-circle-fill"></i> <br />{" "}
                        0.00
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-10 grid-margin stretch-card">
                <div className="card">
                  <div className="card-header">
                    What is Notional Volume and Why Does It Matter?
                  </div>
                  <div className="p-3 px-5">
                    <p className="fs-6">
                      Notional volume in finance is often referred to as the
                      total value of a derivative contract position, determined
                      by multiplying the notional value with the lot size. It
                      represents the nominal or face value of a financial
                      instrument or contract.{" "}
                    </p>
                    <p className="text-muted fs-6">
                      At Koze Global, most promotions use notional volume (USD)
                      from closed trades as one of the key eligibility criteria
                      to qualify for the rewards offered in each promotion.
                    </p>
                    <p className="text-muted fs-6 m-0">
                      Here is how the notional volume in USD is calculated:
                    </p>
                    <p className="fs-6">
                      Notional Volume (USD) = Trading lot X Contract size X
                      Closing price of the underlying assets X End of day
                      product exchange rate{" "}
                    </p>
                    <p className="text-muted fs-6 m-0">
                      To get the notional volume in millions (USD):
                    </p>
                    <p className="fs-6">
                      Notional Volume in millions (USD) = Notional Volume (USD)
                      ÷ 1,000,000{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
