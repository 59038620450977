import { useEffect, useState } from "react";
import "../../assets/css/depositeFund.css";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { updateLoginTimestamp } from "../../redux/loginSlice";

const BASE_API_URL = "https://crmapi.kozeglobal.com/api";
const PAYMENT_API_URL = `${BASE_API_URL}/payment_info.php`;
const DEPOSIT_API_URL = `${BASE_API_URL}/deposit_funds_add_wallet_bal.php`;

export default function DepositFromBank({ balance }) {
  const [bankName, setBankName] = useState("");
  const [accountHolder, setAccountHolder] = useState("");
  const [accountno, setAccountno] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [inputFields, setInputFields] = useState({
    amount: "",
    req_transaction_id: "",
    remark: "",
    deposit_type: "",
  });
  const [receipt, setReceipt] = useState(null);

  const token = localStorage.getItem("userToken");

  const dispatch = useDispatch();

  useEffect(() => {
    const updateTimestamp = () => {
      const currentTime = new Date().getTime();
      localStorage.setItem("loginTimestamp", currentTime.toString());
      dispatch(updateLoginTimestamp());
    };

    updateTimestamp();
  }, [dispatch]);

  useEffect(() => {
    const fetchInfo = async () => {
      try {
        const response = await axios.get(PAYMENT_API_URL, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const data = response.data;

        setBankName(data.data.response.bank_name);
        setAccountHolder(data.data.response.account_holder_name);
        setAccountno(data.data.response.account_number);
        setIfsc(data.data.response.ifsc_code);
      } catch (error) {
        toast.error("Failed to fetch bank and wallet information.");
      }
    };

    fetchInfo();
  }, [token]);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === "file") {
      const file = files[0];
      if (file && file.size > 2 * 1024 * 1024) {
        toast.error("The file size must be less than 2MB.");
        return;
      }
      setReceipt(file);
    } else {
      setInputFields((prevState) => ({
        ...prevState,
        [name]: name === "amount" ? value.replace(/\D/g, "") : value,
      }));
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("amount", inputFields.amount);
    formData.append("req_transaction_id", inputFields.req_transaction_id);
    formData.append("remark", inputFields.remark);
    formData.append("deposit_type", inputFields.deposit_type);
    if (receipt) {
      formData.append("receipt", receipt);
    }
    formData.append("token", token);

    try {
      const response = await axios.post(DEPOSIT_API_URL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("Response:", response);
      toast.success("Deposit submitted successfully.");

      // Clear the form fields and receipt
      setInputFields({
        amount: "",
        req_transaction_id: "",
        remark: "",
        deposit_type: "",
      });
      setReceipt(null);

      // Optionally reset the form manually
      document.getElementById("ewalletfundreqform").reset();
    } catch (error) {
      console.error("Error submitting deposit:", error);
      toast.error("Failed to submit deposit.");
    }
  };

  return (
    <div className="card-body mt-4">
      <h4>Add Wallet Balance (Bank)</h4>
      <b>Current Wallet Fund: {balance} USDT</b>
      <div className="row mt-4">
        <div className="col-md-6">
          <form
            id="ewalletfundreqform"
            name="ewalletfundreqform"
            onSubmit={handleFormSubmit}
          >
            <div className="form-group mt-3">
              <label htmlFor="amount">Enter Amount</label>
              <input
                type="text"
                id="amount"
                name="amount"
                className="form-control"
                placeholder="Enter Amount"
                value={inputFields.amount}
                onChange={handleChange}
                maxLength={5}
                required
              />
            </div>

            <div className="form-group mt-3">
              <label htmlFor="req_transaction_id">Enter Transaction ID</label>
              <input
                type="text"
                id="req_transaction_id"
                name="req_transaction_id"
                className="form-control"
                placeholder="Enter Transaction ID"
                value={inputFields.req_transaction_id}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group mt-3">
              <label htmlFor="receipt">Add Receipt</label>
              <input
                type="file"
                id="receipt"
                name="receipt"
                className="form-control"
                accept="image/*"
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group mt-3">
              <label htmlFor="remark">Enter Remark</label>
              <input
                type="text"
                id="remark"
                name="remark"
                className="form-control"
                placeholder="Enter Remark"
                value={inputFields.remark}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group mt-3">
              <label htmlFor="deposit_type">Select Payment Method</label>
              <select
                id="deposit_type"
                name="deposit_type"
                className="form-control"
                value={inputFields.deposit_type}
                onChange={handleChange}
                required
              >
                <option value="">Select Payment Method</option>
                <option value="cash">Cash</option>
                <option value="online">Online</option>
              </select>
            </div>

            <div className="form-group mt-3">
              <button
                type="submit"
                id="submit"
                className="btn text-black rounded border waves-effect waves-light"
              >
                Submit
              </button>
            </div>
          </form>
        </div>

        <div className="col-md-6">
          <h4>Payment Information</h4>
          <p className="text-danger">
            Note: Deposit Fund into the provided account and upload the deposit
            proof. MUST mention the Transaction ID.
            <br />
            In case you need support, please mail to support@ablestarfx.com
          </p>
          <h6>Bank Name: {bankName}</h6>
          <h6>Account Holder Name: {accountHolder}</h6>
          <h6>Account Number: {accountno}</h6>
          <h6>IFSC Number: {ifsc}</h6>
        </div>
      </div>
    </div>
  );
}
