import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import SideBar from "./sideBar";
import WithdrawFromBank from "./Withdraw/WithdrawFromBank";
import WithdrawFormCash from "./Withdraw/WithdrawFormCash";
import WithdrawFormCrypto from "./Withdraw/WithdrawFormCrypto";
import "../assets/css/depositeFund.css";
import "../assets/css/withdrawFunds.css";
import bankDeposit from "../img/bank-deposit.png";
import cashDeposit from "../img/cash-deposit.png";
import bnb from "../img/chain-bnb-logo.png";
import tron from "../img/chain-tron-logo.png";
import polygon from "../img/chain-polygon-logo.png";
import eth from "../img/chain-eth-logo.png";
import { Card } from "react-bootstrap";

const WithdrawTypes = [
  {
    type: "Withdraw with bank transfer",
    img: bankDeposit,
  },
  {
    type: "Withdraw with Cash",
    img: cashDeposit,
  },
  {
    type: "USDT-BEP20",
    img: bnb,
    chain: "bsc",
  },
  {
    type: "USDT-TRC20",
    img: tron,
    chain: "tron",
  },
  {
    type: "USDT-ETH20",
    img: polygon,
    chain: "polygon",
  },
  {
    type: "USDT-MATIC20",
    img: eth,
    chain: "eth",
  },
];

export default function WithdrawFunds() {
  const [selectedWithdrawType, setSelectedWithdrawType] = useState(0);
  const [currentBalance, setCurrentBalance] = useState(null);
  const token = localStorage.getItem("userToken");
  const currBlnc = useSelector((state) => state.balance.currentBalance);

  useEffect(() => {
    setCurrentBalance(currBlnc);
  }, [currBlnc]);

  useEffect(() => {
    const fetchData = async () => {
      const selectedType = WithdrawTypes[selectedWithdrawType];
      if (selectedType?.apiEndPoint) {
        try {
          const response = await axios.post(selectedType.apiEndPoint, {
            token,
            chain: selectedType.chain,
          });
          console.log("API Response:", response.data);
        } catch (error) {
          console.error("API Error:", error);
        }
      }
    };

    fetchData();
  }, [selectedWithdrawType, token]);

  const handleWithdrawTypeChange = (index) => {
    setSelectedWithdrawType(index);

    document.getElementById(`withdraw-type-${index}`)?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  };

  const renderWithdrawForm = () => {
    switch (selectedWithdrawType) {
      case 0:
        return <WithdrawFromBank balance={currentBalance} />;
      case 1:
        return <WithdrawFormCash balance={currentBalance} />;
      default:
        return (
          <WithdrawFormCrypto
            type={WithdrawTypes[selectedWithdrawType].type}
            chain={WithdrawTypes[selectedWithdrawType].chain}
          />
        );
    }
  };

  return (
    <>
      <SideBar />
      <div className="container-fluid page-body-wrapper" id="WithdrawFunds">
        <div className="main-panel">
          <div className="content-wrapper">
            {/* <div className="page-header mb-4" style={{ marginLeft: "24rem" }}>
              <h3 className="page-title">Withdraw Funds</h3>
            </div> */}

            <Card className="deposite-window p-md-3 bg-light">
              <div className=" text-black">
                <h3 className="funds m-3">Withdraw Funds</h3>
              </div>
              <Card.Body>
                <div className="row">
                  <div className="col-md-3">
                    <div className="WithdrawType d-flex flex-md-column gap-2 pointer">
                      {WithdrawTypes.map((item, index) => (
                        <Card
                          key={item.type}
                          id={`withdraw-type-${index}`}
                          className={`deposit_channel cursor-move ${
                            index === selectedWithdrawType
                              ? "active-channel"
                              : ""
                          }`}
                          onClick={() => handleWithdrawTypeChange(index)}
                        >
                          <Card.Body>
                            <div className="d-flex align-items-center justify-content-between text-decoration-none">
                              <div className="title">{item.type}</div>
                              <div className="logo logo_flex creditOrDebit_icon">
                                <img src={item.img} alt={item.type} />
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      ))}
                    </div>
                  </div>
                  <div className="col-md-9 mt-2 mt-md-0">
                    <div className="channel">{renderWithdrawForm()}</div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}
